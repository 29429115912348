import { Div } from "../component-builtin";

export interface DocExample<T> {
  factory: Factory<T>;
  componentName: string;
  bind: ($c: T, $container: HTMLElement) => void;
}

export interface StoryBook {
  title(): string;

  preview(): StoryBook.Example;
  examples(): StoryBook.Example[];
}

export class StoryBookDefault implements StoryBook {
  title(): string {
    return "Untitled";
  }

  preview(): StoryBook.Example {
    return this.examples()[0]!;
  }

  examples(): StoryBook.Example[] {
    return [
      {
        title: "Example",
        render: () => Div.make(),
      },
    ];
  }
}

export namespace StoryBook {
  export type Default = StoryBookDefault;
  export const Default = StoryBookDefault;

  export interface Example {
    title?: Option<string>;
    description?: Option<string>;
    render: () => HTMLElement;
  }
}
