import { DatePicker } from "../components/input/date-picker";
import { DatePickerStoryBook } from "../components/input/date-picker.example";
import { GESTURE_MANAGER } from "../gesture-manager";
import { StoryBook } from "../ui/doc-example";
import { $jsx } from "./jsx";
import { Pip } from "./pip";
import { Typography } from "./typography";

Pip.components.add(DatePicker);

export class PipStoryBook extends StoryBook.Default {
  title(): string {
    return "Pip";
  }

  examples(): StoryBook.Example[] {
    return [
      {
        render: () => {
          const $t = $jsx(
            "button",
            {
              style: {
                width: "100%",
                maxWidth: "200px",
                marginTop: "8px",
                marginBottom: "2px",
              },
            },
            Typography.p("Show Picture in Picture")
          );
          $t.toggleAttribute("flat", true);

          GESTURE_MANAGER.addPointerEvent($t, {
            onClick: () => {
              Pip.show(new DatePickerStoryBook().examples()[0].render());
            },
          });

          return $t;
        },
      },
    ];
  }
}
