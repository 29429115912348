import { Div } from "../component-builtin";
import { GESTURE_MANAGER } from "../gesture-manager";
import { DocExample } from "../ui/doc-example";
import { Popover } from "./popover";

const example: DocExample<HTMLButtonElement> = {
  factory: () => document.createElement("button"),
  componentName: "Popover",
  bind: ($c: HTMLButtonElement) => {
    $c.textContent = "Toggle Popover";
    $c.toggleAttribute("primary", true);
    $c.style.position = "relative";
    GESTURE_MANAGER.addPointerEvent($c, {
      onClick: () => {
        const $content = Div.make();
        $content.textContent = "Popover Content";
        $content.style.padding = "20px";
        Popover.reveal($c, $content, {
          width: 200,
          spacing: {
            vertical: -2,
          },
        });
      },
    });
  },
};

export default example;
