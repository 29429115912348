import { LonaWebComponent } from "../component";
import { DocExample } from "../ui/doc-example";
import { Prompt } from "./prompt";

const example: DocExample<Prompt> = {
  factory: LonaWebComponent.asFactory(Prompt),
  componentName: "Prompt",
  bind: ($c: Prompt) => {
    $c.bind(Prompt.Props.makeDelete("Are you sure you want to delete me?"));
  },
};

export default example;
