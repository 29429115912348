import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { DomUtils } from "../dom";
import { $qs_maybe } from "../dom-selectors";
import { $$ } from "../fastdom";

@component({
  name: "std-flow-container",
})
export class FlowContainer extends LonaWebComponent {
  private initialized = false;

  private $stack: HTMLElement[] = [];
  private headerResizeObserver = new ResizeObserver(
    (r: ResizeObserverEntry[]) => {
      this.assignStyles({
        "--header-height": r[0].contentRect.height + "px",
      });
    }
  );

  constructor() {
    super();
    this.headerResizeObserver.observe(this.$("header"));
  }

  dismiss() {
    this.initialized = false;
    this.toggleAttribute("animate", false);
  }

  setHeader($e: HTMLElement): FlowContainer {
    $e.slot = "header";
    this.appendChild($e);
    return this;
  }

  pushPage($e: HTMLElement): FlowContainer {
    $e.slot = "page";
    this.appendChild($e);
    this.$stack.push($e);
    this.render(
      !this.initialized
        ? () => {
            this.toggleAttribute("animate", true);
          }
        : null
    );
    return this;
  }

  popPage() {
    this.$stack.pop();
    this.render();
  }

  render(onFinish?: Option<EmptyFunction>) {
    if (this.$stack.length == 0) return;

    const $previousRevealed = $qs_maybe("[slot=page][revealed]", this);
    const $revealed = this.$stack[this.$stack.length - 1];
    if ($previousRevealed == $revealed) return;

    $$.mutate(() => {
      $revealed.toggleAttribute("revealed", true);
      if ($previousRevealed)
        $previousRevealed.toggleAttribute("revealed", false);

      DomUtils.assignStyles($revealed, {
        transform: "none",
        "--x": "-120px",
      });

      $$.measure(() => {
        const rect = $revealed.getBoundingClientRect();

        $$.mutate(() => {
          this.assignStyles({
            "--p-height": rect.height + "px",
            "--p-width": rect.width + "px",
          });
          DomUtils.assignStyles($revealed, {
            transform: "",
            "--x": "0px",
          });
          $previousRevealed &&
            DomUtils.assignStyles($previousRevealed, {
              "--x": "-40px",
            });

          setTimeout(() => {
            onFinish && onFinish();
          });
        });
      });
    });
  }

  static $styles = [
    css`
      :host {
        width: 100%;
        --flow-container-padding: 8px;
      }

      #root {
        position: relative;
        padding: var(--flow-container-padding);
        height: calc(
          var(--p-height, 0px) + var(--header-height, 0px) +
            var(--flow-container-padding) * 2
        );
        width: 100%;
      }

      #root::before {
        content: "";
        position: absolute;
        inset: 0;
        background: white;
        box-shadow: var(--box-shadow);
        border-radius: 8px;
        height: calc(
          var(--p-height, 0px) + var(--header-height, 0px) +
            var(--flow-container-padding) * 2
        );
        width: 100%;
      }

      :host([animate]) #root::before {
        transition: width 0.3s ease, height 0.3s ease;
      }

      slot[name="page"]::slotted(*) {
        position: absolute;
        top: var(--flow-container-padding);
        left: var(--flow-container-padding);
        opacity: 0;
        pointer-events: none;
        transform: translate(var(--x, 0px), var(--header-height, 0px));
        width: calc(100% - calc(var(--flow-container-padding) * 2));
      }

      slot[name="page"]::slotted(*[revealed]) {
        opacity: 1;
        pointer-events: all;
      }

      :host([animate]) slot[name="page"]::slotted(*) {
        transition: opacity 0.3s ease, transform 0.3s ease;
      }

      #header {
        z-index: 1;
      }
    `,
  ];

  static $html = template`
    <std-col id=root>
      <div id=header>
        <slot name=header></slot>
      </div>
      <slot name=page></slot>
    </std-col>
  `;
}

export namespace FlowContainer {
  // export class Controller {
  //   onHeightChange: (heightPx: number) => void = Constants.EMPTY_FUNCTION;
  //   private $stack: HTMLElement[] = [];
  //   constructor() {
  //   }
  //   push($e: HTMLElement) {
  //     this.$stack.push($e);
  //     this.render();
  //   }
  //   render() {
  //     if (this.$stack.length == 0) return;
  //     const $revealed = this.$stack[this.$stack.length - 1];
  //   }
  // }
}
