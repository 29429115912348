import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";

@component({
  name: "std-sheets-icon",
})
export class SheetsIcon extends LonaWebComponent {
  static $styles = [
    css`
      #root {
        background-color: #fbd65b;
        height: var(--icon-button-dimension, 20px);
        width: var(--icon-button-dimension, 20px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
      }
      .notes-button-line {
        height: 2px;
        width: calc(var(--icon-button-dimension, 20px) - 8px);
        margin-bottom: 2.5px;
        background-color: var(--background-color-elevated);
        border-radius: 2px;
      }
    `,
  ];

  static $html = template`
    <div id="root">
      <div class="notes-button-line"></div>
      <div class="notes-button-line"></div>
      <div class="notes-button-line" style=margin-bottom:0px;></div>
    </div>
  `;
}
