import { DocExample } from "@lona/ui/doc-example";
import { List } from "./list";
import { ListCellLayout } from "./list-cell-layout";
import { LonaWebComponent } from "../../component";

const example: DocExample<List> = {
  factory: LonaWebComponent.asFactory(List),
  componentName: "List",
  bind: ($c: List) => {
    $c.style.width = "200px";
    $c.style.maxWidth = "100%";
    $c.style.background = "var(--yellow)";
    $c.style.padding = "4px";
    $c.style.borderRadius = "8px";

    const $cells = [
      "Drag to reorder me",
      "Apple",
      "Blueberry",
      "Carrot",
      "Dragon Fruit",
      "Elderberry",
      "Fig",
      "Honeydew",
      "Jackfruit",
    ].map((title) => ListCellLayout.makeText({ title }));
    $c.bind($cells, () => true);
  },
};

export default example;
