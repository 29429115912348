import { template } from "../component";

const html = template`
  <style>
    .type-container {
      display: inline-block;
      width: 100%;
      margin-top: 40px;
      background-color: white;
    }
    .spacer {
      height: 40px;
      width: 100%;
      background: var(--red);
      border-radius: 4px;
      opacity: 0.5;
    }
    std-block {
      display: inline-block;
      display: flex;
    }
  </style>
  <div style="" slot=component>
    <div class=spacer></div>
    <std-block>
      <h1>Heading level 1</h1>
    </std-block>
    <div class=spacer></div>
    <std-block>
      <h2>Heading level 2</h2>
    </std-block>
    <div class=spacer></div>
    <std-block>
      <h3>Heading level 3</h3>
    </std-block>
    <div class=spacer></div>
    <std-block>
      <h4>Heading level 4</h4>
    </std-block>
    <div class=spacer></div>
    <std-block>
      <h5>Heading level 5</h5>
    </std-block>
    <div class=spacer></div>
    <std-block>
      <h6>Heading level 6</h6>
    </std-block>
    <div class=spacer></div>
    <std-block>
      <p>Paragraph</p>
    </std-block>
    <div class=spacer></div>
    <std-block>
      <p>Quos atque officia quod. <i>Suscipit delectus</i> cupidatat, quia nulla numquam aute cillum proident. Maiores iusto culpa molestias quam id tenetur saepe impedit reiciendis itaque ducimus itaque nostrud. Tempor aliquip, culpa earum itaque sed consequatur minus aliquip reprehenderit nihil veniam tempora do maxime omnis reiciendis elit&nbsp;tempora.</p>
    </std-block>
    <div class=spacer></div>
  </div>
`;

export const TypographyExample = () => {
  return html.content.cloneNode(true);
  // return Column.builder()
  //   .text("Header 1", {
  //     size: "h1",
  //   })
  //   .text("Header 2", {
  //     size: "h2",
  //   })
  //   .text("Header 3", {
  //     size: "h3",
  //   })
  //   .text("Paragraph", {
  //     size: "p",
  //   })
  //   .text("Caption", {
  //     size: "caption",
  //   })
  //   .build();
};
