import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { GESTURE_MANAGER } from "../gesture-manager";
import { TaggedEnum } from "../tagged-enum";
import { Button } from "./button/button";
import { Overlay } from "./overlay";

@component({
  name: "std-prompt",
})
export class Prompt extends LonaWebComponent {
  private overlay: Option<Overlay>;

  static async show(
    props: Prompt.Props,
    overlay: Overlay = Overlay.instance.get()
  ): Promise<Prompt.Completion> {
    return Prompt.makeWith(props).show(props, overlay);
  }

  async show(
    props: Prompt.Props,
    overlay: Overlay = Overlay.instance.get()
  ): Promise<Prompt.Completion> {
    const autoDismissOnCompleteOrCancel =
      props.autoDismissOnCompleteOrCancel ?? true;
    this.overlay = overlay;
    return new Promise((resolve) => {
      overlay.reveal(this, {
        onDismiss: () => {
          resolve(Prompt.Completion.cancel(this));
        },
        position: Overlay.Position.center(-100),
      });
      GESTURE_MANAGER.addPointerEvent(this.$("completion-btn"), {
        onClick: () => {
          if (autoDismissOnCompleteOrCancel) this.dismiss();
          resolve(Prompt.Completion.completion(this));
        },
      });
      GESTURE_MANAGER.addPointerEvent(this.$("cancel-btn"), {
        onClick: () => {
          if (autoDismissOnCompleteOrCancel) this.dismiss();
          resolve(Prompt.Completion.cancel(this));
        },
      });
    });
  }

  static makeWith(props: Prompt.Props) {
    const $c = Prompt.make();
    $c.bind(props);
    return $c;
  }

  dismiss() {
    (this.overlay ?? Overlay.instance.get()).dismiss(this);
  }

  bind(props: Prompt.Props) {
    this.$("title").textContent = props.title;
    this.$("description").textContent = props.description;
    this.$("cancel-btn").textContent = props.cancelText ?? "Cancel";
    this.$("completion-btn").textContent = props.completionText ?? "Confirm";
    this.$("completion-btn").toggleAttribute(
      "primary",
      props.type == null || props.type == "default"
    );
    this.$("completion-btn").toggleAttribute(
      "destructive",
      props.type == "destructive"
    );
  }

  static $styles = [
    Button.$style,
    css`
      :host {
        width: 100%;
        max-width: 360px;
        --std-prompt-background-color: var(--modal-background-color, white);
      }

      #root {
        padding: 12px;
        border-radius: 8px;
        background-color: var(--std-prompt-background-color);
      }

      h1 {
        --margin-top: 8px;
        --margin-bottom: 8px;
      }

      p {
        --margin-top: 8px;
        --margin-bottom: 8px;
      }
    `,
  ];

  static $html = template`
    <std-col id=root>
      <h1 id=title class=h6></h1>
      <p id=description></p>
      <std-row style=margin-top:12px>
        <std-spacer></std-spacer>
        <button id=cancel-btn secondary style=margin-right:8px></button>
        <button id=completion-btn primary></button>
      </std-row>
    </std-col>
  `;
}

export namespace Prompt {
  export type Props = {
    title: string;
    description: string;
    completionText?: Option<string>;
    cancelText?: Option<string>;
    type?: Option<"destructive" | "default">;
    autoDismissOnCompleteOrCancel?: boolean;
  };

  export namespace Props {
    export function makeDelete(
      description: string,
      title?: Option<string>
    ): Props {
      return {
        title: title ?? "Delete Confirmation",
        description,
        type: "destructive",
      };
    }
  }

  export namespace Completion {
    export type Enum = {
      completion: Prompt;
      cancel: Prompt;
    };
    export type Serialized = TaggedEnum<Enum>;
  }

  export class Completion extends TaggedEnum.Base<Completion.Enum> {
    static completion($prompt: Prompt): Completion {
      return new Completion({
        type: "completion",
        completion: $prompt,
      });
    }

    static cancel($prompt: Prompt): Completion {
      return new Completion({
        type: "cancel",
        cancel: $prompt,
      });
    }

    didComplete(): boolean {
      return this.internal.type == "completion";
    }
  }
}
