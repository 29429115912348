import { dev } from "./log";
import { NumRange } from "./range";

export class AVLTree<T extends AVLTree.Node> {
  root: Option<T>;

  insertMany(nodes: T[]) {
    nodes.forEach((n) => this.insert(n));
  }

  insert(node: T) {
    this.root = AVLTree.insert(this.root, node);
  }

  static insert<T extends AVLTree.Node>(root: Option<T>, node: T): Option<T> {
    if (root == null) return node;

    if (node.key < root.key) {
      root.left = AVLTree.insert(root.left, node);
    } else if (node.key > root.key) {
      root.right = AVLTree.insert(root.right, node);
    } else {
      root.mergeWith(node);
    }

    AVLTree.redecorate(root, node);
    const balance = AVLTree.balance(root);

    if (balance > 1) {
      if (node.key < root.left!.key) {
        return AVLTree.rightRotate(root);
      } else {
        root.left = AVLTree.leftRotate(root.left!);
        return AVLTree.rightRotate(root);
      }
    }
    if (balance < -1) {
      if (node.key > root.right!.key) {
        return AVLTree.leftRotate(root);
      } else {
        root.right = AVLTree.rightRotate(root.right!);
        return AVLTree.leftRotate(root);
      }
    }

    return root;
  }

  delete(node: T): Option<T> {
    this.root = AVLTree.delete(this.root, node);
    return this.root;
  }

  static delete<T extends AVLTree.Node>(root: Option<T>, node: T): Option<T> {
    const key = node.key;
    if (!root) return root;
    else if (key < root.key) root.left = AVLTree.delete(root.left, node);
    else if (key > root.key) root.right = AVLTree.delete(root.right, node);
    else {
      // key == root.key
      const stillExists = root.deleteWith(node).nodeStillExists;
      if (stillExists) {
        return root;
      }
      if (!root.left) {
        return root.right;
      } else if (!root.right) {
        return root.left;
      }
      const temp = AVLTree.minValueNode(root.right)!;
      root.replaceContentsWith(temp);
      root.right = AVLTree.delete(root.right, temp);
    }

    AVLTree.redecorate(root, null);

    const balance = AVLTree.balance(root);
    if (balance > 1) {
      if (AVLTree.balance(root.left) >= 0) {
        return AVLTree.rightRotate(root);
      } else {
        root.left = AVLTree.leftRotate(root.left!);
        return AVLTree.rightRotate(root);
      }
    }
    if (balance < -1) {
      if (AVLTree.balance(root.left) <= 0) {
        return AVLTree.leftRotate(root);
      } else {
        root.right = AVLTree.rightRotate(root.right!);
        return AVLTree.leftRotate(root);
      }
    }
    return root;
  }

  static redecorate<T extends AVLTree.Node>(n: T, newNode: Option<T>) {
    n.height = 1 + Math.max(AVLTree.height(n.left), AVLTree.height(n.right));
    n.redecorate(newNode);
  }

  static leftRotate<T extends AVLTree.Node>(z: T): T {
    const y = z.right! as T;
    const t2 = y?.left;
    y.left = z;
    z.right = t2;
    AVLTree.redecorate(z, null);
    AVLTree.redecorate(y, null);
    return y;
  }

  static rightRotate<T extends AVLTree.Node>(z: T): T {
    const y = z.left! as T;
    const t3 = y?.right;
    y.right = z;
    z.left = t3;
    AVLTree.redecorate(z, null);
    AVLTree.redecorate(y, null);
    return y;
  }

  static balance<T extends AVLTree.Node>(node: Option<T>): number {
    if (!node) return 0;
    return AVLTree.height(node.left) - AVLTree.height(node.right);
  }

  static calculateHeight<T extends AVLTree.Node>(node: Option<T>): number {
    if (!node) return 0;
    return (
      1 +
      Math.max(
        AVLTree.calculateHeight(node.left),
        AVLTree.calculateHeight(node.right)
      )
    );
  }

  static height<T extends AVLTree.Node>(node: Option<T>): number {
    if (!node) return 0;
    return node.height;
  }

  static minValueNode<T extends AVLTree.Node>(node: Option<T>): Option<T> {
    if (node == null || node.left == null) return node;
    return AVLTree.minValueNode(node.left);
  }

  dfs(order: "post" | "pre" | "in" = "pre"): IterableIterator<T> {
    return AVLTree.dfs(this.root, order);
  }

  static dfs<T extends AVLTree.Node>(
    node: Option<T>,
    order: "post" | "pre" | "in" = "pre"
  ): IterableIterator<T> {
    const stack: Array<T> = [];
    let current: Option<T> = node;
    while (current) {
      stack.push(current);
      current = order == "pre" ? current?.left : current?.right;
    }
    return {
      [Symbol.iterator]: function (): IterableIterator<T> {
        return this;
      },
      next: function (..._args: [] | [undefined]): IteratorResult<T, any> {
        const node = stack.pop();
        if (!node) {
          return {
            value: undefined,
            done: true,
          };
        }
        current = order == "pre" ? node.right : node.left;
        while (current) {
          stack.push(current);
          current = order == "pre" ? current.left : current.right;
        }
        return {
          value: node,
          done: false,
        };
      },
    };
  }

  static inorder<T extends AVLTree.Node>(
    node: T,
    visit: (n: T, depth: number) => void,
    depth: number = 0
  ) {
    node.left && AVLTree.inorder(node.left, visit, depth + 1);
    visit(node, depth);
    node.right && AVLTree.inorder(node.right, visit, depth + 1);
  }

  static preorder<T extends AVLTree.Node>(
    node: T,
    visit: (n: T, depth: number) => void,
    depth: number = 0
  ) {
    visit(node, depth);
    node.left && AVLTree.preorder(node.left, visit, depth + 1);
    node.right && AVLTree.preorder(node.right, visit, depth + 1);
  }

  print() {
    AVLTree.print(this.root);
  }

  static print<T extends AVLTree.Node>(
    node: Option<T>,
    indent: string = "",
    last: boolean = true
  ) {
    if (node == null) return "<None>";
    if (last) {
      console.log(indent, "R----", node, node.description());
      indent += "     ";
    } else {
      console.log(indent, "L----", node, node.description());
      indent += " |    ";
    }
    AVLTree.print(node.left, indent, false);
    AVLTree.print(node.right, indent, true);
  }

  printRaw() {
    if (this.root == null) return "<None>";
    AVLTree.printRaw(this.root);
  }

  static printRaw<T extends AVLTree.Node>(root: T) {
    AVLTree.inorder(root, (n, d) => {
      console.log("".padStart(d * 2, " "), n);
    });
  }
}

export namespace AVLTree {
  export function withDefaultNodes(values: number[]): AVLTree<DefaultNode> {
    const tree = new AVLTree<DefaultNode>();
    tree.insertMany(values.map((n) => new AVLTree.DefaultNode(n)));
    return tree;
  }

  export interface Node {
    key: number;
    left: Option<this>;
    right: Option<this>;
    height: number;
    replaceContentsWith(n: this): void;
    redecorate(newNode: Option<this>): void;
    description(): Option<any>;

    mergeWith(n: this): void;
    deleteWith(n: this): { nodeStillExists: boolean };
  }

  export class DefaultNode implements Node {
    private _key: number = 0;
    get key(): number {
      return this._key;
    }
    left: Option<this>;
    right: Option<this>;
    height: number = 1;

    constructor(key: number) {
      this._key = key;
    }

    replaceContentsWith(n: Node): void {
      this._key = n.key;
    }

    redecorate(): void {}

    deleteWith(): { nodeStillExists: boolean } {
      return {
        nodeStillExists: false,
      };
    }

    mergeWith(n: this): void {
      throw new Error("default node cannot be merged");
    }

    description(): Option<string> {
      return undefined;
    }
  }

  export function withIntervalSearchNodes(
    values: [number, number][]
  ): AVLTree<IntervalSearchTreeNode<string>> {
    const tree: AVLTree<IntervalSearchTreeNode<string>> = new AVLTree();
    tree.insertMany(values.map((n) => IntervalSearchTreeNode.testNode(n)));
    return tree;
  }

  export class IntervalSearchTreeNode<T> implements Node {
    private _range: NumRange;
    get key(): number {
      return this._range.start;
    }
    left: Option<this>;
    right: Option<this>;
    height: number = 1;
    max: number;
    value: T;

    static testNode(n: [number, number]): IntervalSearchTreeNode<string> {
      return new AVLTree.IntervalSearchTreeNode(
        {
          start: n[0],
          end: n[1],
        },
        `${n[0]}-${n[1]}`
      );
    }

    constructor(range: NumRange, value: T) {
      this._range = range;
      this.max = this._range.end;
      this.value = value;
    }

    replaceContentsWith(n: IntervalSearchTreeNode<T>): void {
      this._range = n._range;
      this.max = n.max;
      this.value = n.value;
    }

    redecorate(): void {}

    getMaxRange() {}

    deleteWith(): { nodeStillExists: boolean } {
      return {
        nodeStillExists: false,
      };
    }

    mergeWith(n: this): void {
      throw new Error("default node cannot be merged");
    }

    description(): Option<any> {
      return this._range;
    }
  }

  export class BucketedIntervalSearchTreeNode<
    ID,
    T extends Identifiable<ID> & HasNumRange
  > implements Node
  {
    get key(): number {
      return this.values[0].range.start;
    }
    left: Option<this>;
    right: Option<this>;
    height: number = 1;
    max: number;
    values: T[];

    constructor(values: T[]) {
      this.values = values;
      this.max = this.maxOfValues();
      let start = values[0].range.start;
      for (const value of values) {
        if (start != value.range.start)
          throw new Error("start values are not equal");
      }
    }

    private maxOfValues(): number {
      let max = this.values[0].range.end;
      for (const value of this.values) {
        max = Math.max(value.range.end);
      }
      return max;
    }

    replaceContentsWith(n: this): void {
      this.max = n.max;
      this.values = n.values;
    }

    redecorate(newNode: Option<this>): void {
      if (newNode) {
        this.max = Math.max(newNode.max, this.max);
      } else {
        this.max = Math.max(
          this.max,
          this.left?.max ?? 0,
          this.right?.max ?? 0
        );
      }
    }

    deleteWith(node: this): { nodeStillExists: boolean } {
      const toDelete = new Map(node.values.map((v) => [v.id, v]));
      this.values = this.values.filter((v) => !toDelete.has(v.id));
      return {
        nodeStillExists: this.values.length > 0,
      };
    }

    mergeWith(n: this): void {
      this.values = this.values.concat(n.values);
      this.max = Math.max(n.max, this.max);
    }

    description(): Option<any> {
      return {
        id: this.values[0]?.id,
        ids: this.values.map((v) => v.id),
        max: this.max,
        values: this.values,
      };
    }

    /*
     Interval Search Tree
     ===========================================================================
    */

    findAllOverlaps(r: NumRange): T[] {
      const results: T[] = [];
      const nodes = [this];
      while (nodes.length > 0) {
        const node = nodes.pop()!;
        for (const v of node.values) {
          if (NumRange.intersects(v.range, r)) results.push(v);
        }
        if (node.left && node.left.max >= r.start) nodes.push(node.left);
        node.right && nodes.push(node.right);
      }
      return results;
    }

    findAllOverlapsExclusiveEnd(r: NumRange): T[] {
      const results: T[] = [];
      const nodes = [this];
      while (nodes.length > 0) {
        const node = nodes.pop()!;
        for (const v of node.values) {
          if (NumRange.intersects(v.range, r) && r.start != v.range.end)
            results.push(v);
        }
        if (node.left && node.left.max >= r.start) nodes.push(node.left);
        node.right && nodes.push(node.right);
      }
      return results;
    }

    // findSingleOverlap(r: NumRange): Option<T> {
    //   let x: Option<this> = this;
    //   while (x != null) {
    //     if      (NumRange.intersects(x._range, r))    return x;
    //     else if (x.left == null)                      x = x.right;
    //     else if (x.left.max < r.start)                x = x.right;
    //     else                                          x = x.left;
    //   }
    //   return null;
    // }

    recalculateAndValidate() {
      this.left?.recalculateAndValidate();
      this.right?.recalculateAndValidate();
      const max = Math.max(this.max, this.left?.max ?? 0, this.right?.max ?? 0);
      if (this.max != max) {
        throw new Error(`not equal! ${this.max} != ${max}`);
      }
      this.max = max;
    }
  }
}

type HasNumRange = {
  range: NumRange;
};
