import { Style } from "../component-styles";
import { StoryBook } from "../ui/doc-example";
import { $jsx } from "../ui/jsx";
import { Shimmer } from "./shimmer";

export class ShimmerStoryBook extends StoryBook.Default {
  title(): string {
    return "Shimmer";
  }

  examples(): StoryBook.Example[] {
    return [
      {
        render: () => {
          const $container = $jsx(
            "std-col",
            {
              style: {
                width: "180px",
                padding: "12px",
                background: "var(--background-color)",
                borderRadius: "8px",
              },
            },
            [
              $jsx(
                "std-block",
                {
                  style: {
                    alignSelf: "start",
                    padding: "2px",
                    borderRadius: "4px",
                    marginBottom: "4px",
                  },
                  className: "shimmer-overlay",
                },
                $jsx("p", {}, "Hello World")
              ),
              $jsx(
                "std-block",
                {
                  style: {
                    alignSelf: "start",
                    padding: "2px",
                    borderRadius: "4px",
                  },
                  className: "shimmer-overlay",
                },
                $jsx("p", {}, "A longer bit of text")
              ),
              Style.makeWith(Shimmer.$style),
            ]
          );

          return $container;
        },
      },
    ];
  }
}
