import { LonaWebComponent, template } from "../component";
import { Div } from "../component-builtin";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { DomUtils } from "../dom";
import { $$ } from "../fastdom";
import { GESTURE_MANAGER } from "../gesture-manager";

@component({
  name: "std-page-indiciator-dots",
})
export class PageIndiciatorDots extends LonaWebComponent {
  connectedCallback() {}

  bind(selectedIndex: number, count: number) {
    DomUtils.clearChildren(this.$("root"));
    for (let i = 0; i < count; ++i) {
      const $pageIndicatorDot = Div.make();
      $pageIndicatorDot.classList.add("page-indicator-dot");
      $pageIndicatorDot.setAttribute("index", String(i));
      if (i == selectedIndex) {
        $pageIndicatorDot.toggleAttribute("selected", true);
      }
      GESTURE_MANAGER.addPointerEvent($pageIndicatorDot, {
        onClick: () => this.bindSelectedIndex(i),
      });
      $$.mutate(() => this.$("root").appendChild($pageIndicatorDot));
    }
  }

  bindSelectedIndex(selectedIndex: number) {
    $$.mutate(() => {
      this.$qs_maybe(`.page-indicator-dot[selected]`)?.toggleAttribute(
        "selected",
        false
      );
      this.$qs(
        `.page-indicator-dot[index="${Math.floor(selectedIndex)}"]`
      ).toggleAttribute("selected", true);
    });
  }

  static $styles = [
    css`
      #root {
        position: relative;
        align-self: center;
        margin-top: 8px;
      }
      .page-indicator-dot {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: var(--tertiary-icon-color);
        margin-right: 4px;
        transition: background-color 0.3s ease;
      }
      .page-indicator-dot:last-child {
        margin-right: 0px;
      }
      .page-indicator-dot[selected] {
        background-color: var(--primary-icon-color);
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <std-row id=root></std-row>
  `;
}
