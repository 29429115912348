import {
  DateTime,
  NaiveDate,
  NaiveTime,
  TimeUnit,
  TimeWithMeridiem,
} from "@lona-chrono";
import { nonnull } from "../../assert";
import { LonaWebComponent, template } from "../../component";
import { component } from "../../component-decorators";
import { css } from "../../component-styles";
import { Constants } from "../../constants";
import { DomUtils } from "../../dom";
import { GESTURE_MANAGER } from "../../gesture-manager";
import { DatePicker } from "../input/date-picker";
import { Popover, PopoverPosition } from "../popover";

@component({
  name: "std-event-details-time-label",
})
export class EventDetailsTimeLabel extends LonaWebComponent {
  private time: Option<DateTime.Range>;
  onUpdateTime: (timeRange: DateTime.Range) => void = Constants.EMPTY_FUNCTION;

  constructor() {
    super();

    let time: Option<TimeUnit>;
    let originalDuration: Option<TimeUnit>;

    const timeTextChange = ($e: HTMLElement, modifyingStart: boolean) => {
      const { start, end } = nonnull(this.time);
      originalDuration = end.time.sub(start.time);

      let isAm = true;
      if (modifyingStart) {
        isAm = end.time.hrsF < 12;
      } else {
        isAm = start.time.hrsF < 12;
      }

      const timemr = TimeWithMeridiem.parse($e.textContent!);
      if (!timemr) {
        $e.toggleAttribute("invalid", true);
        return;
      }

      const resolved = timemr.resolve(isAm ? "am" : "pm");
      if (!resolved) {
        $e.toggleAttribute("invalid", true);
        return;
      }

      time = resolved.time;
      if (modifyingStart) {
        if (
          !resolved.merdiemPresent &&
          resolved.time.hrs > 12 &&
          resolved.time.hrs > end.time.hrsF
        ) {
          time = time.add({ hrs: -12 });
        }
      } else {
        if (
          !resolved.merdiemPresent &&
          resolved.time.hrs < 12 &&
          resolved.time.hrs < start.time.hrsF
        ) {
          time = time.add({ hrs: 12 });
        }
      }
      $e.toggleAttribute("invalid", false);
    };

    const timeTextChangeComplete = (
      $e: HTMLElement,
      modifyingStart: boolean
    ) => {
      $e.toggleAttribute("invalid", false);
      let { start, end } = nonnull(this.time);
      if (!time) {
        this.bind(nonnull(this.time));
        return;
      }
      if (modifyingStart) {
        start = start.withTime(NaiveTime.hours(time.hrs));
        end = start.add(nonnull(originalDuration));
      } else {
        end = end.withTime(NaiveTime.hours(time.hrs));
        if (end.isBefore(start)) {
          start = end.add(nonnull(originalDuration));
        }
      }
      originalDuration = null;
      time = null;
      this.bind(new DateTime.Range(start, end));
      this.onUpdateTime(new DateTime.Range(start, end));
    };

    DomUtils.registerKeyListeners(this.$("start-hour"), {
      onTextChangeDEPRECATED: (_) => timeTextChange(this.$("start-hour"), true),
    });
    this.$("start-hour").onblur = (_) =>
      timeTextChangeComplete(this.$("start-hour"), true);

    DomUtils.registerKeyListeners(this.$("end-hour"), {
      onTextChangeDEPRECATED: (_) => timeTextChange(this.$("end-hour"), false),
    });
    this.$("end-hour").onblur = (_) =>
      timeTextChangeComplete(this.$("end-hour"), false);

    GESTURE_MANAGER.addPointerEvent(this.$("date"), {
      onClick: () => {
        const $datePicker = DatePicker.make();
        $datePicker.bindToToday(NaiveDate.today());
        $datePicker.style.padding = "12px";
        Popover.reveal(this.$("date"), $datePicker, {
          position: PopoverPosition.BOTTOM,
          width: 244,
          spacing: {
            horizontal: -8,
          },
        });
      },
    });
  }

  bind(r: DateTime.Range) {
    this.time = r;
    let dateString = `${r.start.date.dayOfWeek.label}, ${r.start.date.month.name} ${r.start.date.day}`;
    if (!r.start.date.equals(r.end.date)) {
      // TODO: events that span multiple years are ambiguious
      if (r.start.date.month == r.start.date.month) {
        dateString += ` – ${r.start.date.day1}`;
      } else {
        dateString += ` – ${r.end.date.month.name} ${r.end.date.day1}`;
      }
    }
    const $date = this.$("date");
    $date.textContent = dateString;

    this.$("start-hour").textContent = NaiveTime.Formatter.render(
      r.start.time,
      {
        includeAmPm: r.start.time.meridiem != r.end.time.meridiem,
      }
    );
    this.$("end-hour").textContent = NaiveTime.Formatter.render(r.end.time);
  }

  static $styles = [
    css`
      #event-time,
      .inline-editable {
        font-size: 0.85rem;
        font-weight: 400;
        color: var(--tertiary-text-color);
        display: inline-block;
        position: relative;
      }
      .inline-editable:focus {
        outline: none;
        color: var(--secondary-text-color);
        display: inline;
      }
      .inline-editable:hover {
        background-color: transparent;
        text-decoration: underline;
      }
      .inline-editable[invalid]::before {
        background-color: var(--error-background-color);
      }
      .inline-editable[invalid]::after {
        content: "";
        position: absolute;
        top: calc(100% + 4px);
        left: -4px;
        right: 0px;
        bottom: 0px;
        pointer-events: none;
        content: "Invalid Time";
        background-color: #444;
        color: white;
        padding: 4px 8px;
        width: max-content;
        height: fit-content;
        border-radius: 4px;
        font-size: 0.625rem;
      }
      #date[selected]::before,
      .inline-editable::before {
        content: "";
        position: absolute;
        inset: 0;
        pointer-events: none;
        inset: -2px -4px;
        border-radius: 4px;
        z-index: -1;
      }
      #date[selected]::before,
      .inline-editable:focus::before {
        background-color: var(--hover-color);
      }
    `,
  ];

  static $html = template`
    <p id=event-time>
      <span id=date class=inline-editable></span>
      <span>⋅</span>
      <span id=start-hour class=inline-editable contenteditable></span>
      <span style="padding: 0px 2px;">–</span>
      <span id=end-hour class=inline-editable contenteditable></span>
    </p>
  `;
}
