export namespace ScrollUtils {
  // Element to move, time in ms to animate
  // function scrollTo(element, duration) {
  //   var e = document.documentElement;
  //   if (e.scrollTop === 0) {
  //     var t = e.scrollTop;
  //     ++e.scrollTop;
  //     e = t + 1 === e.scrollTop-- ? e : document.body;
  //   }
  //   scrollToC(e, e.scrollTop, element, duration);
  // }

  let handle: number = 0;
  const scrolls: Map<HTMLElement, number> = new Map();

  // Element to move, element or px from, element or px to, time in ms to animate
  export function scrollTo(
    $e: HTMLElement,
    to: number,
    durationMs: number,
    mutateEnd: boolean
  ) {
    // if (durationMs <= 0) return;
    const nextHandle = handle++;
    scrolls.set($e, nextHandle);
    if (!scrolls) return;
    scrollToX(
      $e,
      $e.scrollLeft,
      to,
      new Date().getTime(),
      durationMs,
      easeOutCuaic,
      nextHandle,
      mutateEnd
    );
  }

  export function scrollToX(
    $e: HTMLElement,
    xFrom: number,
    xTo: number,
    startTimeMs: number,
    durationMs: number,
    motion: (t: number) => number,
    handle: number,
    mutateEnd: boolean
  ) {
    const currentHandle = scrolls.get($e);
    if (currentHandle == null || currentHandle != handle) {
      return;
    }

    if (durationMs == 0) {
      $e.scrollLeft = xTo;
      scrolls.delete($e);
      return;
    }

    const nowMs = new Date().getTime();
    const deltaMs = nowMs - startTimeMs;
    const deltaPercent = deltaMs / durationMs;
    if (deltaPercent >= 1 || deltaPercent < 0) {
      $e.scrollLeft = xTo;
      scrolls.delete($e);
      return;
    }

    $e.scrollLeft = xFrom - (xFrom - xTo) * motion(deltaPercent);
    requestAnimationFrame(() => {
      scrollToX(
        $e,
        xFrom,
        xTo,
        startTimeMs,
        durationMs,
        motion,
        handle,
        mutateEnd
      );
    });
  }

  export function easeOutCuaic(t: number): number {
    t--;
    return t * t * t + 1;
  }
}
