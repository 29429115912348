import { LonaWebComponent } from "../component";
import { DocExample } from "../ui/doc-example";
import { Avatar } from "./avatar";

const example: DocExample<Avatar> = {
  factory: LonaWebComponent.asFactory(Avatar) as Factory<Avatar>,
  componentName: "Avatar",
  bind: ($c: Avatar) => {
    const $profile = new Image();
    $profile.src = "https://static.lona.so/examples/profiles/markz.jpg";
    $profile.style.background = "var(--red)";
    $c.setAttribute("size", "32");
    $c.appendChild($profile);
  },
};

export default example;
