import { LonaWebComponent } from "../component";
import { DocExample } from "../ui/doc-example";
import { Avatar } from "./avatar";
import { Facepile } from "./facepile";

const example: DocExample<Facepile> = {
  factory: LonaWebComponent.asFactory(Facepile),
  componentName: "Facepile",
  bind: ($c: Facepile) => {
    $c.setAttribute("size", "32");
    $c.appendChild(
      Avatar.makeWithImage("https://static.lona.so/examples/profiles/markz.jpg")
    );
    $c.appendChild(
      Avatar.makeWithImage(
        "https://static.lona.so/examples/profiles/huberman.jpg"
      )
    );
    $c.appendChild(
      Avatar.makeWithImage("https://static.lona.so/examples/profiles/satya.jpg")
    );
    $c.style.setProperty("--background-color", "var(--black9-opaque)");
  },
};

export default example;
