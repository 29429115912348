import { LonaWebComponent } from "../component";
import { DocExample } from "../ui/doc-example";
import { PageIndiciatorDots } from "./page-indicator-dots";

const example: DocExample<PageIndiciatorDots> = {
  factory: LonaWebComponent.asFactory(PageIndiciatorDots),
  componentName: "Page Indicator Dots",
  bind: ($c: PageIndiciatorDots) => {
    $c.bind(3, 5);
  },
};

export default example;
