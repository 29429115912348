import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";
import { $$ } from "@lona/fastdom";

@component({ name: "component-doc" })
export class ComponentDoc extends LonaWebComponent {
  private lastSize: Option<DOMRectReadOnly>;

  constructor() {
    super();
    new ResizeObserver((e) => {
      const rect = e[0].contentRect;
      const isExpanding = !this.lastSize || this.lastSize.width < rect.width;
      this.lastSize = rect;

      $$.mutate(() => {
        this.$("root").style.setProperty(
          "--p-animation-multiplier",
          String(isExpanding ? 2 : 0.3)
        );
        this.$("root").style.setProperty("--p-width", rect.width + "px");
        this.$("root").style.setProperty("--p-height", rect.height + "px");
      });
    }).observe(this);
  }

  static $styles = [
    css`
      slot[name="title"]::slotted(*) {
        display: none;
      }

      slot[name="component"]::slotted(*) {
        z-index: 1;
        margin-left: auto;
        margin-right: auto;
      }

      #root {
        padding: 40px;
        height: 100%;
      }

      #root::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: var(--p-width, 100%);
        height: var(--p-height, 100%);
        border-radius: 8px;
        background-color: var(--card-background-color);
        transition-property: background-color;
        transition-duration: calc(0.3s * var(--p-animation-multiplier, 1));
        transition-timing-function: ease;
      }
    `,
    css`
      @keyframes flash-background {
        0% {
          background-color: var(--black6);
        }
        50% {
          background-color: var(--black3);
        }
        100% {
          background-color: var(--black6);
        }
      }

      :host([flash]) #root::before {
        animation: flash-background 0.8s ease-in-out;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <slot></slot>
    <div id=root>
      <slot name=title></slot>
      <std-col id=component-container>
        <slot name=component></slot>
      </std-col>
    </div>
  `;
}
