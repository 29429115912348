import { DateTime, Local, TimeUnit } from "@lona-chrono";
import { DocExample } from "../../ui/doc-example";
import { Popover } from "../popover";
import { EventDetailsContent } from "./event-details-content";

const example: DocExample<Popover> = {
  factory: () => Popover.make(),
  componentName: "Event Details",
  bind: ($c: Popover) => {
    $c.assignStyles({
      "--popover-padding": "16px 12px",
    });
    const start = Local.now().nearest(TimeUnit.MIN15);
    const $details = EventDetailsContent.make();
    $details.bind({
      title: "Brunch with Sally",
      time: new DateTime.Range(
        start,
        start.add({
          hrs: 8,
        })
      ),
      onUpdateTime: () => {},
    });
    $c.appendChild($details);
  },
};

export default example;
