import { DateTime, Local } from "@lona-chrono";
import { DocExample } from "../../ui/doc-example";
import { FlightStatusCard } from "./flight-status-card";

const example: DocExample<FlightStatusCard> = {
  factory: () => FlightStatusCard.make(),
  componentName: "Flight Status Card",
  bind: ($c: FlightStatusCard) => {
    const now = Local.now().withTime();
    $c.bind({
      departure: {
        airport: {
          code: "SFO",
          city: "San Francisco",
        },
        scheduledTime: now.add({ hrs: 16, mins: 40 }),
      },
      arrival: {
        airport: {
          code: "SNA",
          city: "Santa Ana",
        },
        scheduledTime: now.add({ hrs: 18, mins: 13 }),
      },
    });
  },
};

export default example;
