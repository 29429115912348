import { LonaWebComponent, template } from "../../component";
import { component } from "../../component-decorators";
import { css } from "../../component-styles";
import { EventDetailsTimeLabel } from "./event-details-time-label";
import { DateTime } from "@lona-chrono";

export namespace EventDetailsContent {
  export type Props = {
    title: string;
    time: DateTime.Range;
    onUpdateTime?: Option<(time: DateTime.Range) => void>;
  };
}

@component({
  name: "std-event-details-content",
})
export class EventDetailsContent extends LonaWebComponent {
  private $timeLabel = EventDetailsTimeLabel.make();

  constructor() {
    super();
    this.$("title").after(this.$timeLabel);
  }

  static makeWith(props: EventDetailsContent.Props): EventDetailsContent {
    const $c = EventDetailsContent.make();
    $c.bind(props);
    return $c;
  }

  bind(props: EventDetailsContent.Props) {
    this.$("title").textContent = props.title;
    this.$timeLabel.bind(props.time);
    if (props.onUpdateTime) this.$timeLabel.onUpdateTime = props.onUpdateTime;
  }

  static $styles = [
    css`
      #title {
        --margin-bottom: 12px;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <std-col id=root>
      <h1 id=title class=h6>Brunch with Friends</h1>
    </std-col>
  `;
}
