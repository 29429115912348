import { LonaWebComponent } from "../component";
import { DocExample } from "../ui/doc-example";
import { SidebarExpandButton } from "./sidebar-expand-button";

const example: DocExample<SidebarExpandButton> = {
  factory: LonaWebComponent.asFactory(SidebarExpandButton),
  componentName: "Sidebar Expand Button",
  bind: ($c: SidebarExpandButton) => {
    $c.style.setProperty("--icon-primary-color", "#333");
    $c.onClick = () => {
      $c.toggleAttribute("revealed");
    };
  },
};

export default example;
