import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";

@component({
  name: "std-icon-button",
})
export class IconButton extends LonaWebComponent {
  static color(
    color: string,
    options?: Optional<{
      squarish: boolean;
      margin: string;
    }>
  ): IconButton {
    return IconButton.makeWith({
      ...options,
      backgroundColor: color,
    });
  }

  static makeWith(
    options?: Optional<{
      content?: HTMLElement;
      backgroundColor: string;
      squarish: boolean;
      margin: string;
    }>
  ): IconButton {
    const $e = IconButton.make();
    options?.content &&
      (options.content.slot = "icon") &&
      $e.appendChild(options.content);
    options?.backgroundColor &&
      ($e.style.backgroundColor = options?.backgroundColor);
    options?.squarish && $e.toggleAttribute("squarish", true);
    options?.margin && ($e.style.margin = options?.margin);
    return $e;
  }

  static $styles = [
    css`
      :host {
        --std-icon-button-dimension: 16px;
        --std-icon-background-color: transparent;
        --std-icon-button-color: var(--secondary-icon-color, #222);
        --std-icon-hover-color: var(--hover-color, rgba(0, 0, 0, 0.06));
        --std-icon-selected-color: var(--selected-color, rgba(0, 0, 0, 0.2));

        position: relative;
        width: var(--std-icon-button-dimension, 16px);
        height: var(--std-icon-button-dimension, 16px);
        cursor: pointer;
        display: grid;
        place-items: center;
      }
      :host::before {
        border-radius: 50%;
        background-color: var(--std-icon-background-color);
        position: absolute;
        content: "";
        inset: -4px;
        pointer-events: none;
      }
      :host(:hover)::before {
        background-color: var(--std-icon-hover-color);
      }
      :host([selected])::before {
        background-color: var(--std-icon-selected-color);
      }
      :host([squarish]),
      :host([square]),
      :host([squarish]):before,
      :host([square])::before {
        border-radius: 5px;
      }
      slot[name="icon"]::slotted(*),
      slot::slotted(*) {
        color: var(--std-icon-button-color, var(--secondary-icon-color));
        width: var(--std-icon-button-dimension);
        height: var(--std-icon-button-dimension);
      }
    `,
  ];

  static $html = template`<slot name=icon></slot><slot></slot>`;
}
