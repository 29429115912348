import { LonaWebComponent, template } from "../../component";
import { component } from "../../component-decorators";
import { css } from "../../component-styles";
import { Constants } from "../../constants";
import { DomUtils } from "../../dom";
import { DomEventUtils } from "../../dom-event-utils";
import { $qs_maybe } from "../../dom-selectors";
import { Placeholder } from "../placeholder";
import { Spotlight } from "./spotlight";
import { SpotlightRow } from "./spotlight-row";

@component({
  name: "std-spotlight-layout",
})
export class SpotlightLayout extends LonaWebComponent {
  private callbacks: SpotlightLayout.Callbacks = SpotlightLayout.NOOP_CALLBACKS;

  get searchContent(): Option<string> {
    return this.$("searchbar").textContent;
  }

  private keydownHotkeys = new Map([
    ["shift-Tab", () => this.focusPreviousCommand()],
    ["Tab", () => this.focusNextCommand()],
    ["ArrowUp", () => this.focusPreviousCommand()],
    ["ArrowDown", () => this.focusNextCommand()],
  ]);

  private keyupHotkeys = new Map([
    [
      "Enter",
      () => {
        const $selected = $qs_maybe<SpotlightRow>("[aria-selected=true]", this);
        if ($selected) this.emitCommand($selected);
        if (this.searchContent?.length == 0) this.dismiss();
      },
    ],
    ["Escape", () => this.dismiss()],
  ]);

  constructor() {
    super();

    this.$("searchbar").onfocus = () => this.callbacks.onSearchFocus();
    this.$("searchbar").onblur = () => this.callbacks.onSearchBlur();
    this.$("searchbar").onkeyup = async (e) => {
      const hotkey = DomUtils.hotkeyIdentifier(e);
      e.stopPropagation();

      // const backspaceOnEmpty =
      //   e.key == "Backspace" && this.searchContent?.length == 0;
      // if (backspaceOnEmpty) {
      //   this.dismiss();
      //   e.preventDefault();
      //   return;
      // }

      // 1) Check keyup
      const keyupAction = hotkey ? this.keyupHotkeys.get(hotkey) : null;
      if (keyupAction) {
        keyupAction();
        e.preventDefault();
        return;
      }

      // 2) Check keydown
      if (hotkey && this.keydownHotkeys.has(hotkey)) {
        e.preventDefault();
        return;
      }

      this.callbacks.onSearchTextChange(this.searchContent);
    };
    this.$("searchbar").onkeydown = async (e) => {
      const hotkey = DomUtils.hotkeyIdentifier(e);
      e.stopPropagation();

      // 1) Check keydown
      const keydownHotkeys = hotkey ? this.keydownHotkeys.get(hotkey) : null;
      if (keydownHotkeys) {
        keydownHotkeys();
        e.preventDefault();
        return;
      }

      // 2) Check keyup
      if (hotkey && this.keyupHotkeys.has(hotkey)) {
        e.preventDefault();
        return;
      }
    };
    this.onpointerup = DomEventUtils.STOP_PROPAGATION;
    this.onpointerdown = DomEventUtils.STOP_PROPAGATION;

    new ResizeObserver(() => {
      this.$("root").style.setProperty(
        "--height",
        this.$("root").offsetHeight + "px"
      );
    }).observe(this.$("root"));
  }

  recycle(): void {
    this.callbacks = SpotlightLayout.NOOP_CALLBACKS;
  }

  focusSearch() {
    DomUtils.setEndOfContenteditable(this.$("searchbar"));
  }

  focusPreviousCommand() {
    const $selected = $qs_maybe("[aria-selected=true]", this);
    if (!$selected) {
      const $firstChild = $qs_maybe(
        "[slot=search-results]:first-of-type",
        this
      );
      $firstChild && this.focusCommand($firstChild);
      return;
    }
    const $previous = $selected.previousElementSibling as HTMLElement;
    if (!$previous) {
      const $lastChild = $qs_maybe("[slot=search-results]:last-of-type", this);
      $lastChild && this.focusCommand($lastChild);
      return;
    }
    this.focusCommand($previous);
  }

  focusNextCommand() {
    const $selected = $qs_maybe("[aria-selected=true]", this);
    if (!$selected) {
      const $firstChild = $qs_maybe(
        "[slot=search-results]:first-of-type",
        this
      );
      $firstChild && this.focusCommand($firstChild);
      return;
    }
    const $next = $selected.nextElementSibling as HTMLElement;
    if (!$next) {
      const $firstChild = $qs_maybe(
        "[slot=search-results]:first-of-type",
        this
      );
      $firstChild && this.focusCommand($firstChild);
      return;
    }
    this.focusCommand($next);
  }

  focusCommand($e: HTMLElement) {
    const $selected = $qs_maybe("[aria-selected=true]", this);
    if ($selected) $selected.ariaSelected = "false";
    $e.ariaSelected = "true";

    const r = $e.getBoundingClientRect();
    const parentR = this.$("search-results").getBoundingClientRect();
    DomUtils.assignStyles(this.$("button-background"), {
      transform: `translate(0px, ${r.top - parentR.top}px)`,
      height: r.height + "px",
    });
  }

  async emitCommand($item: SpotlightRow) {
    this.$("searchbar").blur();
    await $item.command!();
    this.dismiss();
  }

  dismiss(clearContent: boolean = true) {
    this.$("searchbar").blur();
    this.callbacks.onCommandComplete();
    if (clearContent) {
      this.$("searchbar").textContent = "";
    }
  }

  bind(
    results: Spotlight.SearchResult[],
    callbacks: SpotlightLayout.Callbacks
  ) {
    DomUtils.clearChildren(this);
    this.callbacks = callbacks;

    let foundMatch = false;
    let $selectedCommand: Option<HTMLElement>;
    for (const [index, item] of results.entries()) {
      if (item.d == 0 || item.d == 1) {
        foundMatch = true;
      } else if (foundMatch) {
        break;
      }

      const $item = SpotlightRow.makeWith(item.node);
      $item.slot = "search-results";
      $item.onpointerenter = () => this.focusCommand($item);
      $item.onpointerup = () => this.emitCommand($item);
      $item.ariaSelected = String(index == 0);
      if (index == 0) $selectedCommand = $item;
      this.appendChild($item);
    }

    this.$("search-results").toggleAttribute("has-results", results.length > 0);
    if (!foundMatch) this.$("button-background").style.height = "0px";
    if ($selectedCommand) this.focusCommand($selectedCommand);
  }

  bindPlaceholder(placeholder?: Option<string>) {
    this.$("searchbar").setAttribute("placeholder", placeholder ?? "");
  }

  static $styles = [
    Placeholder.$style,
    css`
      :host {
        position: relative;
        display: block;
      }

      #root {
        position: relative;
        width: 100%;
        --p-selection-animation-duration: 0.15s;
      }
      #root::before {
        content: "";
        position: absolute;
        inset: 0;
        pointer-events: none;
        height: var(--height, 100%);
        background-color: var(--background-color-elevated);
        transition-property: height;
        transition-duration: var(--p-selection-animation-duration);
        transition-timing-function: ease;
        z-index: -1;
        box-shadow: var(--box-shadow);
        border-radius: 12px;
      }

      #searchbar {
        width: 100%;
        padding: 16px 20px;
        --placeholder-top: 16px;
      }

      #searchbar:focus {
        text-decoration: none;
        outline: none;
      }

      :host([small]) #searchbar {
        padding: 16px 16px;
        --font-size: var(--p-size);
        --placeholder-top: 16px;
      }

      :host([small]) #root::before {
        border-radius: 8px;
        box-shadow: var(--box-shadow-light);
      }

      #search-results {
        position: relative;
        transform: translate(0px, 0px);
      }
      #search-results[has-results] {
        padding: 4px;
      }
      #search-results[has-results]::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        border-top: 1px solid var(--divider-color);
      }

      #button-background {
        position: absolute;
        top: 0;
        left: 4px;
        width: calc(100% - 8px);
        background-color: var(--hover-color);
        border-radius: 10px;
        transition-property: transform, height, opacity;
        transition-duration: var(--p-selection-animation-duration);
        transition-timing-function: ease;
        z-index: 0;
        pointer-events: none;
        opacity: 0;
      }

      #search-results[has-results] #button-background {
        opacity: 1;
      }

      #flow-container {
        position: absolute;
        top: 0px;
        width: calc(100% - 40px);
        padding: 20px;
        display: none;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <std-col id=root>
      <div id=search-page>
        <std-flex id=searchbar-container>
          <p id=searchbar class=h5 contenteditable></p>
        </std-flex>
        <std-col id=search-results>
          <div id=button-background></div>
          <slot name=search-results></slot>
        </std-col>
      </div>
      <div id=flow-container></div>
    </std-col>
  `;
}

export namespace SpotlightLayout {
  export type Callbacks = {
    onSearchFocus: EmptyFunction;
    onSearchBlur: EmptyFunction;
    onSearchTextChange: (t: Option<string>) => void;
    onCommandComplete: EmptyFunction;
  };

  export const NOOP_CALLBACKS: Callbacks = {
    onSearchFocus: Constants.EMPTY_FUNCTION,
    onSearchBlur: Constants.EMPTY_FUNCTION,
    onSearchTextChange: Constants.EMPTY_FUNCTION,
    onCommandComplete: Constants.EMPTY_FUNCTION,
  };
}
