import { Div } from "../component-builtin";
import { $ } from "../dom-selectors";
import { GESTURE_MANAGER } from "../gesture-manager";
import { DocExample } from "../ui/doc-example";
import { Drawer } from "./drawer";

const example: DocExample<HTMLButtonElement> = {
  factory: () => document.createElement("button"),
  componentName: "Drawer",
  bind: ($c: HTMLButtonElement) => {
    $c.textContent = "Toggle Drawer";
    $c.style.width = "100%";
    $c.style.maxWidth = "200px";
    $c.toggleAttribute("primary", true);

    GESTURE_MANAGER.addPointerEvent($c, {
      onClick: () => {
        const $drawer = Drawer.instance.get();

        const $content = Div.make();
        $content.textContent = "Drawer Content";
        $content.style.padding = "20px";
        $drawer.replaceChildren($content);

        $drawer.toggleReveal();

        GESTURE_MANAGER.setOnClickOutside($drawer, () =>
          $drawer.toggleReveal(false)
        );
      },
    });

    // $c.bindToDate(ViewerContext.Time.today(), ViewerContext.Time.today());
  },
};

export default example;
