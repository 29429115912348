import { LonaWebComponent, template } from "../component";
import { Div } from "../component-builtin";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { Constants } from "../constants";
import { DomUtils } from "../dom";
import { GESTURE_MANAGER } from "../gesture-manager";
import { SimpleKeyframe } from "../keyframe";
import { dev } from "../log";
import { MathUtils } from "../math";
import { VelocityPredictor } from "../time-graph";

type CarouselCallbacks = {
  onScroll: (fromIdx: number, toIdx: number, duration?: Option<number>) => void;
};

@component({
  name: "std-carousel",
})
export class Carousel extends LonaWebComponent {
  private currentPageIndex = 0;
  private $pages: HTMLElement[] = [];
  private callbacks?: Optional<CarouselCallbacks>;
  private predictor: VelocityPredictor = new VelocityPredictor();

  pageStopIdx: number = this.$pages.length;

  domBind(
    pages: Array<HTMLElement | Node>,
    pageStopIdx: number = pages.length,
    callbacks?: Optional<CarouselCallbacks>
  ) {
    this.$pages = [];
    this.pageStopIdx = pageStopIdx;
    this.callbacks = callbacks;

    const $pageContainer = this.$("page-container");

    for (const page of pages) {
      $pageContainer.appendChild(page);
      const $page = $pageContainer.lastElementChild as HTMLElement;
      this.$pages.push($page);

      $page.classList.add("page");

      // ($page as unknown as HasNextPageButton).onNextPage = () => {
      //   if (this.currentPageIndex == 1) {
      //     this.pageStopIdx = this.$pages.length;
      //   }
      //   this.setPage(Math.floor(this.currentPageIndex) + 1, this.pageStopIdx);
      // };
      // ($page as unknown as HasBackPageButton).onBackPage = () => {
      //   this.setPage(Math.floor(this.currentPageIndex) - 1, this.pageStopIdx);
      // };
    }

    this._setPage(this.currentPageIndex, this.pageStopIdx);
  }

  connectedCallback() {
    const SWIPE_GESTURE_LOCK = "SWIPE_GESTURE_LOCK";
    const {
      minAnimationDuration,
      maxAnimationDuration,
      scrollThreshold,
      easing,
      animationDurationMultiple,
    } = {
      minAnimationDuration: 200,
      maxAnimationDuration: 10000,
      // maxAnimationDuration: 800,
      scrollThreshold: 0.15,
      // const easing = "cubic-bezier(.5,.5,.2,1)";
      // const easing = "cubic-bezier(.75,.75,.25,.75)";
      // const easing = "cubic-bezier(.6,.6,.2,1)";
      easing: "cubic-bezier(.6,.6,.25,.85)",
      animationDurationMultiple: 1.0,
    };

    const roundWithDirection = (
      n: number,
      direction: number,
      threshold: number = scrollThreshold
    ): number => {
      if (direction > 0) {
        if (n % 1 > threshold) {
          return Math.ceil(n);
        } else {
          return Math.floor(n);
        }
      } else {
        if (n % 1 < 1 - threshold) {
          return Math.floor(n);
        } else {
          return Math.ceil(n);
        }
      }
    };

    let initialPageIndex = 0;
    GESTURE_MANAGER.addDragGesture(this, SWIPE_GESTURE_LOCK, {
      onPointerDown: () => {
        initialPageIndex = this.currentPageIndex;
        this.predictor.reset();
      },
      onPointerMove: ({ progressX: progress }) => {
        const nowMs = new Date().getTime();
        const newProgress = initialPageIndex + progress / 500;
        this.predictor.pushPoint({ x: nowMs, y: newProgress });
        this._setPage(newProgress, this.pageStopIdx, false);
      },
      onPointerUp: ({ progressX: progress }) => {
        const direction = progress > 0 ? 1 : -1;
        const page = roundWithDirection(
          initialPageIndex + progress / 500,
          direction
        );
        this._setPage(
          MathUtils.clamp(
            roundWithDirection(page, direction),
            0,
            this.pageStopIdx - 1
          ),
          this.pageStopIdx,
          true
        );
      },
    });
  }

  nextPage() {
    this.setPage(this.currentPageIndex + 1);
  }

  previousPage() {
    this.setPage(this.currentPageIndex - 1);
  }

  setPage(
    idx: number,
    stopIdx: number = this.pageStopIdx,
    animate: boolean = true,
    animationDurationMs: number = 500,
    easing: string = "ease"
  ) {
    this._setPage(
      MathUtils.clamp(idx, 0, stopIdx - 1),
      stopIdx,
      animate,
      animationDurationMs,
      easing
    );
  }

  private _setPage(
    idx: number,
    stopIdx: number = this.pageStopIdx,
    animate: boolean = true,
    animationDurationMs: number = 500,
    easing: string = "ease"
  ) {
    const maxPage = this.$pages.length;
    const previousIdxClamped = MathUtils.clamp(
      this.currentPageIndex,
      0,
      stopIdx - 1
    );
    const idxClamped = MathUtils.clamp(idx, 0, stopIdx - 1);
    this.callbacks?.onScroll &&
      this.callbacks.onScroll(
        previousIdxClamped,
        idxClamped,
        animate ? animationDurationMs : null
      );

    const previousTransforms = Carousel.progressToTransform(
      this.currentPageIndex,
      maxPage,
      stopIdx
    );
    const transforms = Carousel.progressToTransform(idx, maxPage, stopIdx);

    this.currentPageIndex = idx;

    for (const [idx, $page] of this.$pages.entries()) {
      const previousScale = `scale(${previousTransforms[idx].scale})`;
      const newScale = `scale(${transforms[idx].scale})`;

      const previousTranslate = `translate(${
        previousTransforms[idx].translate * 100
      }%, 0px)`;
      const newTranslate = `translate(${
        transforms[idx].translate * 100
      }%, 0px)`;

      if (animate) {
        $page.animate(
          SimpleKeyframe.builder()
            .transform(
              previousTranslate + " " + previousScale,
              newTranslate + " " + newScale
            )
            .build(),
          {
            duration: animationDurationMs,
            easing,
          }
        );
      }
      $page.style.transform = newTranslate + " " + newScale;
    }
  }

  static progressToTransform = (
    idx: number,
    cnt: number,
    stopIdx: number = cnt,
    easeFn: (n: number) => number = MathUtils.easeInSine
  ): {
    progress: number;
    translate: number;
    scale: number;
  }[] => {
    const res: Array<{
      progress: number;
      translate: number;
      scale: number;
    }> = [];
    const idxF = Math.floor(idx);
    const idxC = Math.ceil(idx);
    const progress = idx < 0 ? 1 - Math.abs(idx % 1) : idx % 1;
    for (let i = 0; i < cnt; ++i) {
      if (i >= stopIdx) {
        res.push({
          progress: 0,
          translate: i,
          scale: 0.95,
        });
        continue;
      }

      let diff = i - idx;
      if (idx < 0 && i == 0) {
        diff = easeFn(diff) / 3;
      } else if (idx > stopIdx - 1 && i == stopIdx - 1) {
        diff = -easeFn(Math.abs(diff)) / 3;
      }

      let idxProgress = 0;
      if (i == idxF) {
        idxProgress = 1 - progress;
      }
      if (i == idxC && i != idx) {
        idxProgress = progress;
      }
      let scale = MathUtils.lerp(0.95, 1, easeFn(idxProgress));
      res.push({
        progress: idxProgress,
        translate: diff,
        scale,
      });
    }
    return res;
  };

  private static _$styles: HTMLTemplateElement[] = [
    css`
      :host {
        --std-carousel-page-margin: 0px;
        --std-carousel-background-color: var(--background-color, white);
        --std-carousel-card-box-shadow: none;
        --std-carousel-container-box-shadow: 0px 0px 6px 3px rgb(0 0 0 / 12%)
          inset;

        --std-carousel-border: 1px solid var(--std-carousel-background-color);

        width: 100%;
        height: 660px;
        display: flex;
        flex-direction: column;
      }
      .card {
        height: 100%;
      }
      #title {
        margin-top: 80px;
        margin-bottom: 80px;
        font-size: 1.5rem;
      }
    `,
    css`
      #alias-fix {
        position: relative;
        height: 100%;
      }
      #alias-fix:after {
        position: absolute;
        inset: -3px;
        content: "";
        z-index: 1;
        border-radius: 24px;
        border: var(--std-carousel-border);
        pointer-events: none;
      }
      #alias-fix:before {
        position: absolute;
        inset: -1px;
        content: "";
        z-index: 1;
        border-radius: 20px;
        box-shadow: var(--std-carousel-container-box-shadow);
        opacity: 1;
        pointer-events: none;
      }
      #page-container {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        background-color: var(
          --std-carousel-background-color,
          var(--slate-gray)
        );
      }
      .page {
        position: absolute;
        top: 0;
        left: 0;
        margin: var(--std-carousel-page-margin, 0px);
        width: calc(100% - var(--std-carousel-page-margin, 0px) * 2);
        height: calc(100% - var(--std-carousel-page-margin, 0px) * 2);
        padding: 20px;
        border-radius: 20px;
        box-shadow: var(--std-carousel-card-box-shadow);
      }
      .page::before {
        content: "";
        position: absolute;
        inset: 4px;
        background-color: var(--std-carousel-page-background-color);
        border-radius: 20px;
        z-index: -1;
      }
    `,
    css`
      #time-graph {
        position: fixed;
        display: none;
        top: 8px;
        left: 8px;
        z-index: 1;
        opacity: 0.8;
        background-color: var(--background-color);
      }
    `,
  ];
  public static get $styles(): HTMLTemplateElement[] {
    return Carousel._$styles;
  }
  public static set $styles(value: HTMLTemplateElement[]) {
    Carousel._$styles = value;
  }

  static $html = template`
    <div id=alias-fix>
      <std-col id=page-container class="card"></std-col>
    </div>
  `;
}
