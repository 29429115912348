import { DateTime, NaiveTime } from "@lona-chrono";
import { LonaWebComponent, template } from "../../component";
import { component } from "../../component-decorators";
import { css } from "../../component-styles";

export type FlightStatus = {
  departure: {
    airport?: Option<{
      code?: Option<string>;
      city?: Option<string>;
    }>;
    scheduledTime: Option<DateTime>;
  };
  arrival: {
    airport?: Option<{
      code?: Option<string>;
      city?: Option<string>;
    }>;
    scheduledTime: Option<DateTime>;
  };
};

@component({
  name: "lona-flight-status-card",
})
export class FlightStatusCard extends LonaWebComponent {
  static parseFromTitle(title: string, dt: DateTime) {
    const FLIGHT_REGEX = /Flight to \D+ \(([a-zA-Z]+) (\d+)\)/gm;

    const match = FLIGHT_REGEX.exec(title);
    if (!match) return false;

    const flight = {
      airline: match[1],
      code: match[2],
      flightDateStr: dt.date.rfc3339(),
    };
  }

  bindLoading() {
    this.$("departure-airport-code").textContent = "—";
    this.$("arrival-airport-code").textContent = "—";
    this.$("departure-scheduled-time").textContent = "—";
    this.$("arrival-scheduled-time").textContent = "—";
    this.$("departure-airport-city").textContent = "—";
    this.$("arrival-airport-city").textContent = "—";
  }

  bind(status: FlightStatus) {
    this.$("departure-airport-code").textContent =
      status.departure.airport?.code ?? "—";
    this.$("arrival-airport-code").textContent =
      status.arrival.airport?.code ?? "—";
    this.$("departure-scheduled-time").textContent =
      FlightStatusCard.scheduledTimeDisplay(status.departure.scheduledTime);
    this.$("arrival-scheduled-time").textContent =
      FlightStatusCard.scheduledTimeDisplay(status.arrival.scheduledTime);
    this.$("departure-airport-city").textContent =
      status.departure.airport?.city ?? "—";
    this.$("arrival-airport-city").textContent =
      status.arrival.airport?.city ?? "—";
  }

  static scheduledTimeDisplay(dt: Option<DateTime>): string {
    if (!dt) return "-";
    return NaiveTime.Formatter.render(dt.time);
  }

  static $styles = [
    css`
      :host {
        min-width: 300px;
      }

      #root {
        background: var(--background-color);
        border-radius: 8px;
        height: calc(100% - 24px);
        padding: 20px 16px;
        justify-content: space-between;
      }
      caption {
        font-size: 0.875rem;
      }
      p {
        --line-height-ratio: 1.1;
      }
      .scheduled-time {
        --margin-bottom: 8px;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <std-col id=root>
      <std-row style="align-items:center;">
        <std-flex><h1 class=h5 id=departure-airport-code></h1></std-flex>
        <span class=p style="padding:0px 8px;">→</span>
        <h1 class=h5 id=arrival-airport-code>NYC</h1>
      </std-row>
      <std-row style=margin-top:16px;>
        <div style="flex-basis:50%;flex-shrink:0;border-right:1px solid var(--divider-color);padding-right: 8px;">
          <std-col>
            <p class=scheduled-time id=departure-scheduled-time>10:45pm</p>
            <p id=departure-airport-city>San Francisco</p>
          </std-col>
        </div>
        <div style=flex-basis:50%;flex-shrink:0;margin-left:8px;>
          <std-col>
            <p class=scheduled-time id=arrival-scheduled-time>12:00pm</p>
            <p id=arrival-airport-city>New York</p>
          </std-col>
        </div>
      </std-row>
    </std-col>
  `;
}
