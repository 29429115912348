import { nonnull } from "../../assert";
import { LonaWebComponent, template } from "../../component";
import { Div } from "../../component-builtin";
import { component } from "../../component-decorators";
import { css } from "../../component-styles";
import { $$ } from "../../fastdom";
import { dev } from "../../log";
import { NumRange } from "../../range";
import { TaggedEnum } from "../../tagged-enum";
import { ViewportObserver } from "../../ui/viewport-observer";

type Rectangle = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type ColumnState = {
  columnWidth: number;
  columnOffsets: number[];
  columnHeights: number[];
};

export namespace GridLayout {
  export type Config = {
    blockSpacingPx: number;
    inlineSpacingPx: number;
    minWidthPx: number;
  };
}

@component({
  name: "std-grid-layout",
})
export class GridLayout extends LonaWebComponent {
  private _ids: string[] = [];
  get ids(): string[] {
    return this._ids;
  }

  private viewport: Option<NumRange>;
  private pinRects: {
    $e: HTMLElement;
    rect: Rectangle;
  }[] = [];
  private columnState: Option<ColumnState>;
  private preloadId: Option<number>;

  static readonly DEFAULT_CONFIG: GridLayout.Config = {
    blockSpacingPx: 4,
    inlineSpacingPx: 4,
    minWidthPx: 400,
  };

  private _config: GridLayout.Config = GridLayout.DEFAULT_CONFIG;
  get config(): GridLayout.Config {
    return this._config;
  }

  constructor() {
    super();
    this.$qs<HTMLSlotElement>("slot").onslotchange = () =>
      $$.mutate(() => this.layout());
  }

  connectedCallback() {
    new ResizeObserver(this.onWindowSizeChange).observe(this);
  }

  disconnectedCallback() {
    removeEventListener("resize", this.onWindowSizeChange);
    if (this.preloadId) cancelIdleCallback(this.preloadId);
  }

  watchViewport(observer: ViewportObserver) {
    observer.addViewportListener(this.onViewportChange);
  }

  onViewportChange = (viewport: NumRange) => {
    // Padding so we can preload things faster
    this.viewport = {
      start: viewport.start - 1000,
      end: viewport.end + 1000,
    };
    if (this.columnState) {
      this.render();
    }
  };

  onWindowSizeChange = this.layout.bind(this);

  // $pinPreview(pinId: string): PinPreview {
  //   return nonnull(this.$pinPreview_maybe(pinId));
  // }

  // $pinPreview_maybe(pinId: string): Option<PinPreview> {
  //   return this.$qs_maybe<PinPreview>(`[image-id="${pinId}"]`);
  // }

  calculatePinRanges(): NumRange {
    const ranges = this.pinRects.map((r) => ({
      start: r.rect.y,
      end: r.rect.y + r.rect.height,
    }));

    const findLastIndex = function <T>(arr: T[], pred: (t: T) => boolean) {
      let k = arr.length - 1;
      while (k >= 0) {
        const kValue = arr[k];
        if (pred(kValue)) {
          return k;
        }
        k -= 1;
      }
      return -1;
    };

    if (this.viewport) {
      return {
        start: ranges.findIndex((r) => r.end > this.viewport!.start) - 20,
        end: findLastIndex(ranges, (r) => r.start < this.viewport!.end) + 20,
      };
    } else {
      return {
        start: 0,
        end: Number.MAX_SAFE_INTEGER,
      };
    }
  }

  bind(config: GridLayout.Config) {
    this._config = config;
    this.layout();
  }

  layout() {
    const { width } = this.$("root").getBoundingClientRect();

    const numColumns = Math.max(1, Math.floor(width / this._config.minWidthPx));
    const columnState = (this.columnState = GridLayout.generateColumnState(
      numColumns,
      width,
      this._config.inlineSpacingPx
    ));

    this.$("root").style.setProperty(
      "--p-column-width",
      columnState.columnWidth + "px"
    );
    const $children = [...this.children] as HTMLElement[];
    this.pinRects = $children.map(($child) => {
      const height = $child.offsetHeight;

      const minHeight = Math.min(...columnState.columnHeights);
      const minHeightIndex = columnState.columnHeights.indexOf(minHeight);
      const x =
        columnState.columnOffsets[minHeightIndex] +
        this._config.inlineSpacingPx * minHeightIndex;
      const y = minHeight;

      // dev(
      //   $child,
      //   height,
      //   minHeightIndex,
      //   columnState.columnHeights[minHeightIndex]
      // );
      columnState.columnHeights[minHeightIndex] +=
        height + this._config.blockSpacingPx;

      return {
        $e: $child,
        rect: {
          x,
          y,
          width,
          height,
        },
      };
    });

    this.render();
  }

  render() {
    if (this.preloadId) cancelIdleCallback(this.preloadId);

    const pinRange = this.calculatePinRanges();

    this.pinRects.map((r, index) => {
      const {
        $e,
        rect: { x, y },
      } = r;

      if (index < pinRange.start || index > pinRange.end) {
        $$.mutate(() => {
          $e.parentElement?.removeChild($e);
        });
      } else {
        $$.mutate(() => {
          $e.style.removeProperty("display");
          $e.style.removeProperty("visibility");
          $e.style.transform = `translate(${x}px, ${y}px)`;
        });
      }
    });

    const y = Math.max(...this.columnState!.columnHeights);
    const $spacer = this.$_maybe("spacer") ?? Div.make();
    $$.mutate(() => {
      $spacer.id = "spacer";
      $spacer.style.height = "24px";
      $spacer.style.transform = `translate(0px, ${y}px)`;
      this.$("root").append($spacer);
    });
  }

  static generateColumnState(
    numColumns: number,
    width: number,
    horizontalSpacing: number = 4
  ): ColumnState {
    const columnWidth =
      (width - horizontalSpacing * (numColumns - 1)) / numColumns;
    const columnOffsets: number[] = [];
    for (let i = 0; i < numColumns; ++i) {
      columnOffsets.push(columnWidth * i);
    }
    const columnHeights = Array(numColumns).fill(4);
    return {
      columnWidth,
      columnOffsets,
      columnHeights,
    };
  }

  static $styles = [
    css`
      #root {
        position: relative;
        --p-column-width: 100%;
        --p-transition: transform 0.5s ease;
      }

      slot::slotted(*) {
        position: absolute;
        top: 0;
        left: 0;
        width: var(--p-column-width);
        transition: var(--p-transition);
      }

      :host([no-transition]) slot::slotted(*) {
        transition: none !important;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <div id=root>
      <slot></slot>
    </div>
  `;
}
