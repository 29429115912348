import { Div } from "../component-builtin";
import { DomUtils } from "../dom";
import { $$ } from "../fastdom";
import { GESTURE_MANAGER } from "../gesture-manager";
import { Hotkeys } from "../hotkeys";
import { LazySync } from "../lazy";
import { TaggedEnum } from "../tagged-enum";

export class Overlay {
  private $parent: HTMLElement;
  private $overlay = Div.make();
  private revealed: boolean = false;
  private onClick: Option<EmptyFunction>;

  private $revealed: Option<HTMLElement>;

  static readonly zIndex = 999999;
  private animatingElements: Map<HTMLElement, number> = new Map();

  constructor(
    $parent: HTMLElement,
    borderRadius: string = "",
    background: string = "rgba(0,0,0,0.3)"
  ) {
    DomUtils.assignStyles(this.$overlay, {
      position: "absolute",
      zIndex: String(Overlay.zIndex),
      background,
      opacity: String(0),
      transition: `opacity 0.3s ease`,
      inset: String(0),
      height: `100%`,
      width: `100%`,
      borderRadius,
    });
    $parent.appendChild(this.$overlay);
    this.$parent = $parent;
    GESTURE_MANAGER.addPointerEvent(this.$overlay, {
      onClick: () => this.onClick && this.onClick(),
    });
  }

  static readonly instance: LazySync<Overlay> = new LazySync(
    () => new Overlay(document.body)
  );

  private toggleOverlayVisibility(revealed: boolean = !this.revealed) {
    $$.defer(() => {
      DomUtils.assignStyles(this.$overlay, {
        opacity: String(this.revealed ? 1 : 0),
        pointerEvents: this.revealed ? "" : "none",
      });
    });
  }

  static isRevealed($e: HTMLElement): boolean {
    return Overlay.instance.get().$revealed == $e;
  }

  static reveal(
    $e: HTMLElement,
    options?: Optional<{
      onDismiss: EmptyFunction;
      position: Overlay.Position;
    }>
  ) {
    Overlay.instance.get().reveal($e, options);
  }

  static dismiss($e: HTMLElement, onDismiss?: Option<EmptyFunction>) {
    Overlay.instance.get().dismiss($e, onDismiss);
  }

  reveal(
    $e: HTMLElement,
    options?: Optional<{
      onDismiss: EmptyFunction;
      position: Overlay.Position;
    }>
  ) {
    if (this.$revealed == $e) {
      return;
    }

    if (this.$revealed != null) {
      this.dismiss(this.$revealed);
      return;
    }

    this.revealed = true;

    if (this.animatingElements.has($e)) {
      clearTimeout(this.animatingElements.get($e)!);
      this.animatingElements.delete($e);
    }

    const { top, transformY } = (
      options?.position ?? Overlay.Position.DEFAULT
    ).match({
      topPx: (topPx) => ({ top: topPx + "px", transformY: "0px" }),
      center: ({ offsetPx }) => ({
        top: `calc(50% + ${offsetPx ?? 0}px)`,
        transformY: "-50%",
      }),
    });

    $$.mutate(() => {
      this.$parent.appendChild(
        DomUtils.assignStyles($e, {
          position: "fixed",
          top,
          left: "50%",
          transform: `translate(-50%, calc(${transformY} + var(--slide-in-y, 0px)))`,
          zIndex: String(Overlay.zIndex + 1),
          transition: `transform 0.3s ease, opacity 0.3s ease`,
          "--slide-in-y": "-40px",
          opacity: String(0),
        })
      );
    });
    $$.defer(() => {
      DomUtils.assignStyles($e, {
        "--slide-in-y": "0px",
        opacity: "1",
        pointerEvents: "",
      });
    });

    Hotkeys.pushEscapeHandler(this, () => {
      this.dismiss($e);
    });
    this.$revealed = $e;
    this.toggleOverlayVisibility(true);
    this.onClick = () => {
      this.dismiss($e);
      options?.onDismiss && options.onDismiss();
    };
  }

  dismiss($e: HTMLElement, onDismiss?: Option<EmptyFunction>) {
    if (this.$revealed != $e) return;

    Hotkeys.clearEscapeHandler(this);
    this.revealed = false;
    this.toggleOverlayVisibility(false);
    this.$revealed = null;
    $$.mutate(() => {
      DomUtils.assignStyles($e, {
        "--slide-in-y": "-40px",
        opacity: "0",
        pointerEvents: "none",
      });
      this.animatingElements.set(
        $e,
        setTimeout(() => {
          // NOTE: semantics here are not very clear -

          // DomUtils.removeFromDom($e) also puts the element back into its
          // a pool to be recycled.
          //
          // Some components might want to keep a reference to the object?
          //
          DomUtils.removeFromDom($e, false);
          this.animatingElements.delete($e);
          onDismiss && onDismiss();
        }, 300)
      );
    });
  }
}

export namespace Overlay {
  export namespace Position {
    export type Enum = {
      topPx: number;
      center: Option<{
        offsetPx?: number;
      }>;
    };
    export type Serialized = TaggedEnum<Enum>;
  }

  export class Position extends TaggedEnum.Base<Position.Enum> {
    static CENTER = new Position({ type: "center" });
    static DEFAULT = Position.topPx(100);
    static topPx(topPx: number): Position {
      return new Position({
        type: "topPx",
        topPx,
      });
    }
    static center(offsetPx: number): Position {
      return new Position({
        type: "center",
        center: {
          offsetPx,
        },
      });
    }
  }
}
