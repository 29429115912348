import { LonaWebComponent } from "../component";
import { Column, Div } from "../component-builtin";
import { CSSLength } from "../css-length";
import { DomUtils } from "../dom";
import { GESTURE_MANAGER } from "../gesture-manager";
import { DocExample } from "../ui/doc-example";
import { SidebarContainer } from "./sidebar-container";

const example: DocExample<SidebarContainer> = {
  factory: LonaWebComponent.asFactory(SidebarContainer),
  componentName: "Sidebar Container",
  bind: ($c: SidebarContainer) => {
    $c.assignStyles({
      height: "500px",
      width: "300px",
      maxWidth: "100%",
      display: "block",
      borderRadius: "8px",
      background: "var(--green)",
      "--sidebar-container-top": "0px",
      "--sidebar-container-height": "100%",
    });
    $c.sidebarWidth = CSSLength.fromPx(100);
    $c.bind();

    const $content = Column.make();
    $content.slot = "main-content";
    DomUtils.assignStyles($content, {
      height: "500px",
      alignItems: "center",
      justifyContent: "center",
    });
    $content.textContent = "Click me";
    GESTURE_MANAGER.addPointerEvent($content, {
      onClick: () => $c.toggleStickied(),
    });

    $c.appendChild($content);
  },
};

export default example;
