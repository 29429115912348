import { LonaWebComponent, template } from "../../component";
import { component } from "../../component-decorators";
import { css, Style } from "../../component-styles";
import { $ } from "../../dom-selectors";
import { $$ } from "../../fastdom";
import { GESTURE_MANAGER } from "../../gesture-manager";
import { $jsx } from "../../ui/jsx";

@component({
  name: "std-form",
})
export class Form extends LonaWebComponent {
  bind(props: Form.Props) {
    for (const input of props.inputs ?? []) {
      const $label = document.createElement("label");
      $label.classList.add("label");
      $label.setAttribute("for", input.name);
      $label.textContent = input.label;
      $$.mutate(() => this.appendChild($label));

      const $input = document.createElement("input");
      $input.name = input.name;
      $input.type = input.type ?? "text";
      $$.mutate(() => this.appendChild($input));
    }

    if (props.submit) {
      const label = props.submit.label;
      const $button = $jsx(
        "button",
        {
          id: "submit",
          attributes: {
            primary: "",
          },
          style: {
            alignSelf: "flex-end",
          },
        },
        label
      );
      GESTURE_MANAGER.addPointerEvent($button, {
        onClick: props.submit.onClick,
      });
      $$.mutate(() => this.appendChild($button));
    }
  }

  static $styles: Style.Compat[] = [
    css`
      :host {
        padding: 12px;
        #border: 1px solid black;
        border-radius: 8px;
        background: var(--background-color);
      }

      form {
        display: flex;
        flex-direction: column;
        margin: 0;
      }

      slot::slotted(label) {
        margin-left: 4px !important;
        --margin-bottom: 8px;
      }

      slot::slotted(input[type="text"]) {
        background: var(--hover-color);
        padding: 8px 8px;
        border: 0px;
        border-radius: 8px;
      }

      slot::slotted(input:not(:last-child)) {
        margin-bottom: 16px;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <form>
      <slot></slot>
    </form>
  `;
}

export namespace Form {
  export type Input = {
    name: string;
    label: string;
    type?: Option<"text">;
  };

  export type Submit = {
    label: string;
    onClick: () => void;
  };

  export type Props = Optional<{
    inputs: Input[];
    submit: Submit;
  }>;

  export function builder(): Builder {
    return new Builder();
  }

  export class Builder {
    private readonly inputs: Input[] = [];
    private _submit: Option<Submit>;

    input(input: Input): Builder {
      this.inputs.push(input);
      return this;
    }

    submit(submit: Submit): Builder {
      this._submit = submit;
      return this;
    }

    build(): Form {
      const $f = Form.make();
      $f.bind({
        inputs: this.inputs,
        submit: this._submit,
      });
      return $f;
    }
  }
}
