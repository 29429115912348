import { LonaWebComponent } from "../component";
import { DocExample } from "../ui/doc-example";
import { LonaIcons, PlusIcon } from "../ui/icons";
import { IconButton } from "./icon-button";

const example: DocExample<IconButton> = {
  factory: LonaWebComponent.asFactory(IconButton),
  componentName: "Icon Button",
  bind: ($c: IconButton) => {
    $c.toggleAttribute("squarish");
    $c.appendChild(LonaIcons.$svg(PlusIcon));
  },
};

export default example;
