import "@lona/components/icons/sheets-icon";

import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";
import { Button } from "../button/button";

@component({
  name: "std-auth-page",
})
export class AuthPage extends LonaWebComponent {
  static $styles = [
    css`
      :host {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        container-type: inline-size;
      }

      #root {
        width: 100%;
        height: 100%;
      }

      #side-content {
        display: none;
      }

      .half-section {
        flex-basis: 100%;
      }

      @container (width > 960px) {
        .half-section {
          flex-basis: 50%;
        }

        #side-content {
          display: block;
        }
      }

      #main-content {
        padding: 12px;
      }
    `,
    Button.$style,
    css`
      #banner {
        background-color: var(--hover-color);
        border-radius: 12px;
        padding: 8px 8px;
        color: #666;
      }

      #content {
        align-self: center;
        max-width: 400px;
        width: 100%;
      }

      #side-content {
        padding: 2px;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <std-row id=root>
      <std-col id=side-content class=half-section>
        <div style=background:#18181b;width:100%;height:100%;border-radius:8px;></div>
      </std-col>
      <std-col id=main-content class=half-section>
        <std-center id=banner>
          <p>Try for free for 14 days. No credit card required.</p>
        </std-center>

        <std-spacer></std-spacer>
        <std-col id=content style=align-items:center;>
          <slot name=title></slot>
          <div style=height:200px;></div>
          <slot name=button></slot>
          <p style=--margin-top:32px;color:var(--tertiary-text-color);text-align:center;>
            By signing up, I agree to the <slot name=privacy-policy></slot> and <slot name=tos></slot>.
          </p>
        </std-col>
        <std-spacer></std-spacer>

      </std-col>
    </std-row>
  `;
}
