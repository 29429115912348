import { dbg, info } from "./log";

export enum DARK_MODE_TYPE {
  DARK,
  LIGHT,
  UNSET,
}

export namespace DarkMode {
  let darkModeActive = false;

  function init() {
    if (isDarkMode()) {
      document.documentElement.setAttribute("theme", "dark");
      darkModeActive = true;
    } else {
      document.documentElement.removeAttribute("theme");
      darkModeActive = false;
    }
  }

  export function isDarkMode(): boolean {
    const theme = localStorage.getItem("theme");
    if (theme) {
      return theme == "dark";
    } else if (!window.matchMedia) {
      return false;
    } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return true;
    }
    return false;
  }

  export function toggle(force: Option<boolean> = !isDarkMode()) {
    info("ui")("darkmode/toggle/", force);
    document.documentElement.toggleAttribute("transitioning-theme", true);
    dbg(localStorage.getItem("theme"));
    if (force == null) {
      localStorage.removeItem("theme");
    } else if (force) {
      localStorage.setItem("theme", "dark");
    } else {
      localStorage.setItem("theme", "light");
    }
    dbg(localStorage.getItem("theme"));

    if (isDarkMode()) {
      document.documentElement.setAttribute("theme", "dark");
      darkModeActive = true;
    } else {
      document.documentElement.removeAttribute("theme");
      darkModeActive = false;
    }

    document.dispatchEvent(new CustomEvent("theme-changed"));

    setTimeout(() => {
      document.documentElement.toggleAttribute("transitioning-theme", false);
    }, 100);
  }

  init();
}
