import { DocExample } from "@lona/ui/doc-example";
import { SpotlightLayout } from "../spotlight/spotlight-layout";
import { Spotlight } from "../spotlight/spotlight";
import { dev } from "../../log";
import { LonaWebComponent } from "../../component";
import { $ } from "../../dom-selectors";

const example: DocExample<SpotlightLayout> = {
  factory: LonaWebComponent.asFactory(SpotlightLayout),
  componentName: "Spotlight",
  bind: ($c: SpotlightLayout, $container: HTMLElement) => {
    $container.style.height = "200px";
    // NOTE: callers *should* *really* tell this component how wide it should be,
    $c.style.width = "100%";

    const nullState: Spotlight.Row[] = [
      {
        priority: 0,
        name: "Some Default Actions",
        icon: function (): string | HTMLElement | Node {
          return "A";
        },
        searchIndices: ["Food"],
        command: async () => {},
      },
    ];

    const rows: Spotlight.Row[] = [
      {
        priority: 0,
        name: "Apple",
        icon: function (): string | HTMLElement | Node {
          return "A";
        },
        searchIndices: ["Food"],
        command: async () => {},
      },
      {
        priority: 0,
        name: "Blueberry",
        icon: function (): string | HTMLElement | Node {
          return "B";
        },
        searchIndices: ["Food"],
        command: async () => {},
      },
      {
        priority: 0,
        name: "Carrot",
        icon: function (): string | HTMLElement | Node {
          return "C";
        },
        searchIndices: ["Food"],
        command: async () => {},
      },
      {
        priority: 0,
        name: "Shoe",
        icon: function (): string | HTMLElement | Node {
          return "S";
        },
        searchIndices: ["Clothes"],
        command: async () => {},
      },
    ];

    $c.bindPlaceholder("Search");
    $c.onSearchTextChange = (t) => {
      dev("searching: ", t);
      const searchResult = Spotlight.search(t, rows, () => []);
      $c.bind(searchResult);
    };
    $c.onSearchFocus = () => {
      $container.style.zIndex = String(99999);
      $c.bind(Spotlight.Row.toSearchResults(nullState));
    };
    $c.onSearchBlur = () => {
      $container.style.removeProperty("z-index");
      $c.bind([]);
    };
  },
};

export default example;
