import { Center, Row } from "../component-builtin";
import { css, Style } from "../component-styles";
import { DomUtils } from "../dom";
import { GESTURE_MANAGER } from "../gesture-manager";
import { DocExample } from "../ui/doc-example";
import { ContextMenu } from "./context-menu";

const example: DocExample<HTMLElement> = {
  factory: Row.make,
  componentName: "Button Group",
  bind: ($c: HTMLElement, $container: HTMLElement) => {
    const base = {
      height: "200px",
      width: "120px",
      borderRadius: "8px",
      transition: "background 0.3s ease",
      justifyContent: "center",
      fontSize: "0.8125rem",
    };
    const actionThatDismisses = (state: { shouldDismiss: boolean }) => {
      state.shouldDismiss = true; // default
    };
    const actionThatPersists = (state: { shouldDismiss: boolean }) => {
      state.shouldDismiss = false;
    };

    {
      const $child1 = Center.make();
      DomUtils.assignStyles($child1, base);
      $child1.classList.add("context-menu-target");
      $child1.toggleAttribute("first");
      $child1.style.marginRight = "8px";
      $child1.textContent = "Context Menu";
      GESTURE_MANAGER.addPointerEvent($child1, {
        onContextMenu: (p) =>
          ContextMenu.builder()
            .cellRow("Apple", null, actionThatDismisses)
            .cellRow("Orange", null, actionThatDismisses)
            .cellRow("Pear", null, actionThatDismisses)
            .buildAndReveal($child1, p),
      });
      $c.appendChild($child1);
    }
    {
      const $child2 = Center.make();
      DomUtils.assignStyles($child2, base);
      $child2.classList.add("context-menu-target");
      $child2.toggleAttribute("second");
      $child2.textContent = "Right Click";
      GESTURE_MANAGER.addPointerEvent($child2, {
        onContextMenu: (p) =>
          ContextMenu.builder()
            .cellRow("Pie", null, actionThatPersists)
            .cellRow("Cake", null, actionThatPersists)
            .cellRow("Croissant", null, actionThatPersists)
            .buildAndReveal($child2, p),
      });
      $c.appendChild($child2);
    }

    $container.appendChild(
      Style.makeWith(css`
        .context-menu-target[first] {
          background: var(--red);
        }
        .context-menu-target[second] {
          background: var(--yellow);
        }
        .context-menu-target[context-menu-open] {
          background: var(--orange);
        }
      `)
    );
  },
};

export default example;
