import { LonaWebComponent } from "../component";
import { DocExample } from "../ui/doc-example";
import { Switch } from "./switch";

const example: DocExample<Switch> = {
  factory: LonaWebComponent.asFactory(Switch),
  componentName: "Switch",
  bind: ($c: Switch) => {
    $c.addPointerEvent({
      onClick: () => {
        $c.toggleAttribute("on");
      },
    });
  },
};

export default example;
