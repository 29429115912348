import { NaiveDate } from "@lona-chrono";
import { StoryBook } from "../../ui/doc-example";
import { DatePicker } from "./date-picker";

export class DatePickerStoryBook extends StoryBook.Default {
  title(): string {
    return "Date Picker List";
  }

  examples(): StoryBook.Example[] {
    return [
      {
        render: () => {
          const $c = DatePicker.make();
          const today = NaiveDate.today();
          $c.bindToDate(today, today);
          return $c;
        },
      },
    ];
  }
}
