import { nonnull } from "../../assert";
import { LonaWebComponent, template } from "../../component";
import { Div } from "../../component-builtin";
import { component } from "../../component-decorators";
import { css } from "../../component-styles";
import { Constants } from "../../constants";
import { $$ } from "../../fastdom";
import { GESTURE_MANAGER } from "../../gesture-manager";
import { ArrowDownIcon, LonaIcons } from "../../ui/icons";
import { Button } from "../button/button";
import { Tooltip } from "../tooltip";
import { NaiveDate, Weekday } from "@lona-chrono";

@component({
  name: "std-date-picker",
})
export class DatePicker extends LonaWebComponent {
  onSelectedDate: (date: NaiveDate) => Option<void | boolean> =
    Constants.EMPTY_FUNCTION;

  private monthToRender: Option<NaiveDate>;
  private selectedDate: Option<NaiveDate>;
  private today: Option<NaiveDate>;
  private dateRange: Option<NaiveDate.Range>;

  constructor() {
    super();
    const $container = this.$("container");
    for (let i = 0; i < 56; ++i) {
      const $child = Div.make();
      $container.appendChild($child);
    }
    GESTURE_MANAGER.addPointerEvent(this.$("prev-month"), {
      onClick: () => {
        const previousMonth = this.monthToRender!.add({ mths: -1 });
        this.bind(previousMonth, this.selectedDate, nonnull(this.today));
      },
    });
    GESTURE_MANAGER.addPointerEvent(this.$("next-month"), {
      onClick: () => {
        const nextMonth = this.monthToRender!.add({ mths: 1 });
        this.bind(nextMonth, this.selectedDate, nonnull(this.today));
      },
    });
  }

  setDateRange(r: NaiveDate.Range) {
    this.dateRange = r;
    this.rebindDateRange();

    if (r.start.isBefore(this.monthToRender!)) {
      const previousMonth = this.monthToRender!.add({
        mths: -1,
      });
      this.bind(previousMonth, this.selectedDate, this.today!);
    }

    const nextMonth = this.monthToRender!.add({
      mths: 1,
    });
    if (nextMonth.isBefore(r.end)) {
      this.bind(nextMonth, this.selectedDate, this.today!);
    }
  }

  private rebindDateRange() {
    const $children = this.$("container").children;
    const month = this.monthToRender!;
    let currentDate: NaiveDate = month.mthIsoStart();
    let $first: Option<HTMLElement>;
    let $last: Option<HTMLElement>;
    for (let i = 8; i < 56; ++i) {
      const $child = $children[i] as HTMLElement;
      if (i % 8 == 0) continue;
      const isFirst = $first == null;
      const inRange = this.dateRange
        ? this.dateRange.contains(currentDate)
        : false;
      $$.mutate(() => {
        $child.classList.toggle("is-within-range", inRange);
        // todo: first/last of type selectors don't work....
        $child.toggleAttribute("first-within-range", isFirst);
        $child.toggleAttribute("last-within-range", false);
      });
      if (!inRange) {
        currentDate = currentDate.add({ days: 1 });
        continue;
      }
      $first = $child;
      $last = $child;
      currentDate = currentDate.add({ days: 1 });
    }
    if ($last)
      $$.mutate(() => $last!.toggleAttribute("last-within-range", true));
  }

  bindToDate(date: NaiveDate, today: NaiveDate) {
    this.bind(date, date, today);
  }

  bindToToday(today: NaiveDate) {
    this.bind(today, null, today);
  }

  private bind(
    date: NaiveDate,
    selectedDate: Option<NaiveDate>,
    today: NaiveDate
  ) {
    const monthStart = date.mthStart();

    this.today = today;
    this.monthToRender = monthStart;
    this.selectedDate = selectedDate;

    GESTURE_MANAGER.addPointerEvent(this.$("today-button"), {
      onClick: () => this.onSelectedDate!(today),
    });

    const $title = this.$("title");
    $title.textContent = `${monthStart.month.name} ${monthStart.yr}`;

    let currentMonth = monthStart;
    const $children = this.$("container").children;
    let currentDate: NaiveDate = monthStart.mthIsoStart();
    for (let i = 1; i < 56; ++i) {
      const $child = $children[i] as HTMLElement;
      $child.onpointerup = null;
      if (i < 8) {
        const dow1 = i % 7;
        $child.textContent = Weekday.DOW1[dow1].initialCaps;
        $child.style.color = "#888";
        continue;
      }
      if (i % 8 == 0) {
        const wno = String(currentDate.wno);
        $child.textContent = wno;
        $child.classList.add("week-number");
        $child.setAttribute("tooltip", `Week ${wno}`);
        continue;
      }
      $child.style.color =
        currentMonth.month == currentDate.month
          ? "var(--color)"
          : "var(--tertiary-text-color)";
      $child.classList.add("day");
      $child.toggleAttribute(
        "selected",
        selectedDate != null && selectedDate.equals(currentDate)
      );
      $child.toggleAttribute("today", today.equals(currentDate));
      $child.textContent = String(currentDate.day1);

      const date = currentDate;
      GESTURE_MANAGER.addPointerEvent($child, {
        onClick: () => {
          const maybeValid = this.onSelectedDate!(date);
          if (maybeValid == undefined || maybeValid) {
            this.bind(date, date, today);
          }
        },
      });

      currentDate = currentDate.add({ days: 1 });
    }

    this.rebindDateRange();
  }

  static $icons: Option<string[]> = [LonaIcons.symbol(ArrowDownIcon)];
  static $styles = [
    Button.$style,
    css`
      :host {
        box-sizing: content-box;
        user-select: none;
        width: 236px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }
      #title {
        font-size: 0.9rem;
        flex-grow: 1;
        align-self: center;
        justify-self: center;
      }
      #container {
        position: relative;
        flex-grow: 1;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-column-gap: 0px;
        font-size: 10px;
        margin-top: 12px;
        height: 192.5px;
      }
      #container::before {
        position: absolute;
        content: "";
        background-color: var(--card-background-color);
        top: calc(100% / 7);
        bottom: 0px;
        left: 0px;
        width: calc(100% / 7 - 4px);
        z-index: -1;
        border-radius: 4px;
      }
      #container div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .nav-button {
        padding: 4px;
      }
      #today-button {
        padding: 2px 6px;
        font-size: 0.75rem;
        background-color: rgba(0, 0, 0, 0.09);
        margin-right: 4px;
        border-radius: 4px;
      }
      .nav-button:hover,
      #today-button:hover {
        background-color: var(--hover-color, rgba(0, 0, 0, 0.12));
      }
    `,
    css`
      .day {
        cursor: pointer;
        border-radius: 50%;
      }
      .day:hover::after,
      .day[selected]::after,
      .day[today]::after {
        position: absolute;
        content: "";
        background-color: #999cfe99;
        inset: 0;
        top: 2px;
        bottom: 2px;
        border-radius: 6px;
        z-index: -1;
      }
      .day[selected]::after {
        background-color: #999cfe;
        box-shadow: var(--box-shadow);
      }
      .is-within-range::before {
        position: absolute;
        content: "";
        background: var(--selected-color);
        inset: 0;
        top: 2px;
        bottom: 2px;
        pointer-events: none;
        user-select: none;
      }
      [first-within-range]::before {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      [last-within-range]::before {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    `,
    css(/* css */ `
      .week-number {
        position: relative;
        cursor: pointer;
      }
      .week-number:hover::after {
        ${Tooltip.mixin}
        left: calc(100% + 8px);
        width: max-content;
        z-index: 1;
      }
    `),
  ];

  static $html = template`
    <std-row style="flex-direction:row-reverse;margin-top:0px;">
      <button class=nav-button>
        <svg id=next-month height=12 width=12 style=transform:rotate(270deg)>
          <use href=#arrow-down />
        </svg>
      </button>
      <button class=nav-button>
        <svg id=prev-month height=12 width=12 style=transform:rotate(90deg)>
          <use href=#arrow-down />
        </svg>
      </button>
      <div style=flex-grow:1;></div>
      <button id=today-button>Today</button>
    </std-row>

    <std-col style=margin-top:20px;margin-bottom:8px;>
      <div id=title></div>
    </std-col>

    <div id="container"></div>
  `;
}
