import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";

@component({
  name: "std-facepile",
})
export class Facepile extends LonaWebComponent {
  static $styles = [
    css`
      :host([size="16"]) {
        --_dim: 16px;
      }
      :host([size="20"]) {
        --_dim: 20px;
      }
      :host([size="24"]) {
        --_dim: 24px;
      }
      :host([size="28"]) {
        --_dim: 28px;
      }
      :host([size="32"]) {
        --_dim: 32px;
      }
      slot::slotted(*) {
        margin-right: -8px;
        border: 2px solid
          var(--std-facepile-border-color, var(--background-color));
        border-radius: 50%;
      }
      slot::slotted(*:last-child) {
        margin-right: 0px;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <std-row>
      <slot></slot>
    </std-row>
  `;
}
