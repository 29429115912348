import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { ListCellLayout } from "../components/list/list-cell-layout";
import { DomUtils } from "../dom";
import { Typography } from "./typography";

@component({
  name: "std-nested-list",
})
export class NestedList extends LonaWebComponent {
  static makeRoot(rows: NestedList.Row[]): NestedList {
    const $e = NestedList.make();
    $e.bind({
      id: "root",
      children: rows,
    });
    return $e;
  }

  static makeWith(row: NestedList.Row, depth: number = 0): NestedList {
    const $e = NestedList.make();
    $e.bind(row, depth);
    return $e;
  }

  bind(row: NestedList.Row, depth: number = 0) {
    DomUtils.clearChildren(this);
    if (row.label) {
      const $label = ListCellLayout.makeText({
        title: row.label,
      });
      $label.style.marginLeft = depth * 20 + "px";
      $label.slot = "label";
      this.appendChild($label);
    }
    if (row.children) {
      const $children = row.children.map((c) =>
        NestedList.makeWith(c, depth + 1)
      );
      this.append(...$children);
    }
  }

  static $html: Option<HTMLTemplateElement> = template`
    <std-col style=width:100%;>
      <slot name=label></slot>
      <slot></slot>
    </std-col>
  `;
}

export namespace NestedList {
  export type Row = {
    id: string;
    label?: Option<string>;
    children?: Option<Row[]>;
  };
}
