import { StoryBook } from "../../ui/doc-example";
import { Form } from "./form";

export class FormStoryBook extends StoryBook.Default {
  title(): string {
    return "Date Picker List";
  }

  examples(): StoryBook.Example[] {
    return [
      {
        render: () => {
          return Form.builder()
            .input({
              name: "first-name",
              label: "First Name",
            })
            .input({
              name: "last-name",
              label: "Last Name",
            })
            .build();
        },
      },
    ];
  }
}
