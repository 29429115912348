import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { mutate } from "../fastdom";
import { LazySync } from "../lazy";

@component({
  name: "std-drawer",
})
export class Drawer extends LonaWebComponent {
  @mutate
  toggleReveal(force: boolean = !this.hasAttribute("revealed")) {
    this.toggleAttribute("revealed", force);
  }

  private static _instance = new LazySync(() => {
    const $drawer = Drawer.make();
    $drawer.assignStyles({
      position: "fixed",
      height: `calc(100vh - var(--p-margin-top))`,
      width: "450px",
      right: "0px",
      top: "var(--p-margin-top)",
    });
    document.body.appendChild($drawer);
    return $drawer;
  });
  static get instance(): LazySync<Drawer> {
    return this._instance;
  }

  static $styles = [
    css`
      :host {
        --p-margin-top: var(--std-drawer-margin-top, 0px);

        transition: transform 0.3s ease;
        transform: translateX(100%);
      }

      #root {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        background: var(--background-color);
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        box-shadow: var(--box-shadow);
      }

      :host([revealed]) {
        transform: translateX(0px);
      }
    `,
  ];
  static $html = template`
    <div id=root>
      <slot></slot>
    </div>
  `;
}
