import { LonaWebComponent } from "../component";
import { DocExample } from "../ui/doc-example";
import { ButtonGroupRow } from "./button-group-row";

type ExampleEnum = "text" | "number";

const example: DocExample<ButtonGroupRow<ExampleEnum>> = {
  factory: LonaWebComponent.asFactory(ButtonGroupRow) as Factory<
    ButtonGroupRow<ExampleEnum>
  >,
  componentName: "Button Group",
  bind: ($c: ButtonGroupRow<ExampleEnum>) => {
    // NOTE: callers *should* tell this component how wide it should be
    $c.style.width = "200px";
    $c.bind("text", [
      {
        label: "Number",
        identifier: "number",
      },
      {
        label: "Text",
        identifier: "text",
      },
    ]);
  },
};

export default example;
