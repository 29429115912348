import { LonaWebComponent } from "../component";
import { DocExample } from "../ui/doc-example";
import { EditableText } from "./editable-text";

const example: DocExample<EditableText> = {
  factory: LonaWebComponent.asFactory(EditableText),
  componentName: "Editable Text",
  bind: ($c: EditableText) => {
    $c.style.width = "200px";
    $c.bind(null, "Type Here");
    $c.enableEditing();
  },
};

export default example;
