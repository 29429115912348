import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { Constants } from "../constants";
import { DomUtils } from "../dom";
import { DomEventUtils } from "../dom-event-utils";
import { $$ } from "../fastdom";
import { GESTURE_MANAGER } from "../gesture-manager";
import { MathUtils } from "../math";
import { NumRange } from "../range";
import { $jsx } from "../ui/jsx";

@component({
  name: "std-apple-slider",
})
export class DefaultSlider extends LonaWebComponent {
  onProgressChanged: (progress: number) => void = Constants.EMPTY_FUNCTION;

  private state = new Slider.State(Slider.DEFAULT_BOUNDS, 0, 140, 10);

  constructor() {
    super();

    $$.measure(() =>
      this.render(this.state.withWidthPx(this.$("track").offsetWidth))
    );

    this.onpointerdown = DomEventUtils.STOP_PROPAGATION;
    this.onpointerup = DomEventUtils.STOP_PROPAGATION;

    {
      let baseValue: Option<Slider.State>;
      GESTURE_MANAGER.addDragGesture(this.$("thumb"), "slider", {
        onPointerDown: () => {
          baseValue = this.state;
        },
        onPointerMove: ({ progressX: progressPx }) =>
          this.render(
            baseValue!.withProgressPx(
              baseValue!.layout().progressPx - progressPx
            )
          ),
      });
    }

    this.$("track").onpointerup = (e) =>
      this.render(this.state.withProgressPx(e.offsetX - 9));
  }

  private render(state: Slider.State) {
    this.state = state;
    const vm = state.layoutValue(state.findClosestSnap(state.value));

    this.$("range-low").textContent = String(
      Math.round(this.state.range.start)
    );
    this.$("range-high").textContent = String(Math.round(this.state.range.end));
    this.$("thumb").style.transform = `translateX(${vm.progressPx}px)`;
    this.$("progress").style.setProperty(
      "--current-progress",
      vm.progressPx + "px"
    );
    this.$("label").style.display = "block";
    this.$("label").textContent = Math.round(this.state.value) + "px";
    const labelWidthPx = this.$("label").getBoundingClientRect().width;
    this.$("label").style.transform = `translateX(calc(${
      vm.progressPx + 11 - labelWidthPx / 2
    }px))`;
  }

  setBounds(bounds: NumRange, value: number) {
    this.render(this.state.withValue(value).withRange(bounds));
  }

  static $styles = [
    css`
      :host {
        --std-slider-track-color: var(--hover-color, rgba(0, 0, 0, 0.03));
        --std-slider-progress-color: var(--background-color, white);
        --std-slider-thumb-color: var(--background-color, white);
        --std-slider-thumb-border-color: var(
          --divider-color,
          rgba(0, 0, 0, 0.12)
        );

        position: relative;
      }

      #track {
        position: relative;
        height: 20px;
        width: 100%;
        background: var(--std-slider-track-color);
        border-radius: 11px;
        overflow: hidden;
        border: 1px solid var(--divider-color);
      }

      #progress {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        background: var(--std-slider-progress-color);
        transform: translateX(calc(var(--current-progress, 0px) - 100% + 10px));
        pointer-events: none;
      }

      #thumb {
        position: relative;
        height: 18px;
        width: 18px;
        margin: 0px;
        margin-top: -1px;
        margin-left: -1px;
        border-radius: 10px;
        background: var(--std-slider-thumb-color);
        z-index: 999;
      }

      #thumb::before {
        position: absolute;
        content: "";
        top: 0px;
        left: 0px;
        height: 18px;
        width: 18px;
        border-radius: 10px;
        background: var(--std-slider-thumb-color);
        border: 1px solid var(--std-slider-thumb-border-color);
        pointer-events: none;
        opacity: 0.8;
      }

      #thumb::after {
        position: absolute;
        content: "";
        top: 1px;
        left: 1px;
        height: 20px;
        width: 20px;
        border-radius: 9px;
        pointer-events: none;
        opacity: 0.8;
      }

      #label {
        position: absolute;
        left: 0px;
        top: -2px;
        z-index: 1;
        font-size: 0.7125rem;
        color: var(--secondary-text-color);
        background: var(--background-color);
        border-radius: 2px;
        padding: 2px 6px;
        display: none;
      }

      #label-row {
        position: relative;
        width: 100%;
        justify-content: space-between;
        margin-top: 4px;
        font-size: 0.7125rem;
        padding-left: 4px;
        padding-left: 4px;
        color: var(--tertiary-text-color);
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <std-row id=track>
      <div id=progress></div>
      <div id=thumb></div>
    </std-row>
    <std-row id=label-row>
      <div id=range-low>0</div>
      <div id=range-high>100</div>
      <div id=label></div>
    </std-row>
  `;
}

@component({
  name: "std-thick-slider",
})
export class ThickSlider extends LonaWebComponent {
  onProgressChanged: (progress: number) => void = Constants.EMPTY_FUNCTION;

  private state = new Slider.State(Slider.DEFAULT_BOUNDS, 0, 200, 4);

  private labelTimeout: Option<number>;

  constructor() {
    super();

    $$.measure(() =>
      this.render(this.state.withWidthPx(this.$("track").offsetWidth), true)
    );

    this.onpointerdown = DomEventUtils.STOP_PROPAGATION;
    this.onpointerup = DomEventUtils.STOP_PROPAGATION;

    {
      let baseValue: Option<Slider.State>;
      GESTURE_MANAGER.addDragGesture(this.$("thumb"), "slider", {
        onPointerDown: () => {
          baseValue = this.state;
        },
        onPointerMove: ({ progressX: progressPx }) =>
          this.render(
            baseValue!.withProgressPx(
              baseValue!.layout().progressPx - progressPx
            )
          ),
      });
    }

    this.$("track").onpointerup = (e) =>
      this.render(this.state.withProgressPx(e.offsetX + 6));
  }

  setBounds(bounds: NumRange, value: number) {
    this.render(this.state.withValue(value).withRange(bounds));
  }

  setSnap(snap: number[]) {
    this.render(this.state.withSnap(snap));
  }

  private render(state: Slider.State, init: boolean = false) {
    this.state = state;
    const snappedValue = state.findClosestSnap(state.value);
    const vm = state.layoutValue(snappedValue);
    DomUtils.assignStyles(this.$("progress"), {
      "--current-progress": vm.progressPx + 6 + "px",
      backgroundColor: vm.progressPx == 0 ? "transparent" : "",
      transition:
        state.snap != null && state.snap.length > 0
          ? "width 0.1s ease, background-color 0.3s ease"
          : "background-color 0.3s ease",
    });

    this.$("progress-label-tooltip").textContent =
      Math.round(snappedValue) + "";
    this.labelTimeout && clearTimeout(this.labelTimeout);
    this.labelTimeout = setTimeout(() => {
      this.$("label-tooltip").style.opacity = "0";
      this.labelTimeout = null;
    }, 2_000);

    !init && (this.$("label-tooltip").style.opacity = "1");
    const labelWidthPx = this.$("label-tooltip").getBoundingClientRect().width;
    this.$("label-tooltip").style.transform = `translate(calc(${MathUtils.clamp(
      vm.progressPx + 5 - labelWidthPx / 2,
      0,
      198 - labelWidthPx
    )}px), calc(-100% - 4px))`;

    if (this.state.snap) {
      DomUtils.clearChildren(this.$("snap"));
      for (const notch of this.state.snap) {
        if (notch == this.state.range.start || notch == this.state.range.end) {
          continue;
        }
        this.$("snap").appendChild(
          $jsx("div", {
            style: {
              "--position": String(this.state.layoutValue(notch).progressPx),
            },
          })
        );
      }
    }
  }

  static $styles = [
    css`
      :host {
        position: relative;
      }

      #track {
        height: 48px;
        width: 200px;
        background: white;
        border-radius: 8px;
        padding: 1px;
      }

      #progress {
        position: relative;
        width: calc(var(--current-progress));
        min-width: 12px;
        height: 100%;
        background-color: var(--black12);
        border-radius: 8px;
        transition: background-color 0.3s ease;
      }

      #thumb {
        width: 12px;
        height: 100%;
        cursor: ew-resize;
        position: absolute;
        right: 0px;
      }

      #thumb::after {
        position: absolute;
        content: "";
        top: 8px;
        bottom: 8px;
        right: 4px;
        width: 4px;
        border-radius: 2px;
        background: var(--black33);
      }

      #label-tooltip {
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        padding: 4px 8px;
        background: var(--red);
        border-radius: 8px;
        transition: opacity 0.15s ease;
      }

      #progress-label-tooltip {
        color: var(--secondary-text-color);
        color: white;
        font-size: var(--h6-size);
      }

      #label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        left: 16px;
      }

      #snap * {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        background: var(--black12);
        border-radius: 2px;
        left: calc(var(--position) * 1px + -1px);
      }
    `,
  ];

  static $html = template`
    <std-col id=label-tooltip style=opacity:0;>
      <p id=progress-label-tooltip></p>
    </std-col>
    <std-col id=track>
      <div id=progress>
        <div id=thumb></div>
      </div>
      <div id=snap></div>
      <div id=label>
        <slot></slot>
      </div>
    </std-col>
  `;
}

export namespace Slider {
  export type Default = DefaultSlider;
  export const Default = DefaultSlider;

  export type Thick = ThickSlider;
  export const Thick = ThickSlider;

  export type ViewModel = {
    progressPx: number;
    progressPct: number;
  };

  export const DEFAULT_BOUNDS: NumRange = { start: 0, end: 300 };

  export class State {
    readonly range: NumRange;
    readonly value: number;
    readonly inlinePaddingPx: number;
    readonly widthPx: number;
    readonly snap: Option<number[]>;

    constructor(
      range: NumRange,
      value: number,
      widthPx: number,
      inlinePaddingPx: number,
      snap: Option<number[]> = null
    ) {
      this.range = range;
      this.value = value;
      this.widthPx = widthPx;
      this.inlinePaddingPx = inlinePaddingPx;
      this.snap = snap;
    }

    findClosestSnap(value: number): number {
      if (this.snap == null || this.snap.length == 0) return value;

      let snap = this.snap[0];
      let delta = Number.MAX_SAFE_INTEGER;
      for (const notch of this.snap) {
        const candDelta = Math.abs(notch - value);
        if (candDelta > delta) continue;

        snap = notch;
        delta = candDelta;
      }

      return snap;
    }

    layoutValue(value: number): ViewModel {
      const effectiveWidthPx = this.widthPx - this.inlinePaddingPx * 2;
      const rangeWidth = this.range.end - this.range.start;
      const progressPct = (value - this.range.start) / rangeWidth;
      const progressPx = progressPct * effectiveWidthPx;

      return {
        progressPct,
        progressPx,
      };
    }

    layout(): ViewModel {
      return this.layoutValue(this.value);
    }

    withValue(value: number): State {
      return new State(
        this.range,
        value,
        this.widthPx,
        this.inlinePaddingPx,
        this.snap
      );
    }

    withRange(range: NumRange): State {
      return new State(
        range,
        this.value,
        this.widthPx,
        this.inlinePaddingPx,
        this.snap
      );
    }

    withWidthPx(widthPx: number): State {
      return new State(
        this.range,
        this.value,
        widthPx,
        this.inlinePaddingPx,
        this.snap
      );
    }

    withSnap(snap: number[]): State {
      return new State(
        this.range,
        this.value,
        this.widthPx,
        this.inlinePaddingPx,
        snap
      );
    }

    withProgressPx(progressPx: number): State {
      const effectiveWidthPx = this.widthPx - this.inlinePaddingPx * 2;
      const widthPct = progressPx / effectiveWidthPx;
      const value = MathUtils.clamp(
        MathUtils.lerp(this.range.start, this.range.end, widthPct),
        this.range.start,
        this.range.end
      );
      return new State(
        this.range,
        value,
        this.widthPx,
        this.inlinePaddingPx,
        this.snap
      );
    }
  }
}
