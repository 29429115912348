import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";

@component({
  name: "std-pill-button",
})
export class PillButton extends LonaWebComponent {
  static makeWith(t: string, options?: PillButton.Options) {
    const $pill = PillButton.make();
    $pill.bind(t, options);
    return $pill;
  }

  bind(t: string, options?: PillButton.Options) {
    this.textContent = t;
    this.assignStyles({
      "--pill-button-color": options?.color ?? "",
      "--pill-button-background": options?.background ?? "",
      "--pill-button-border-radius": options?.borderRadius
        ? options.borderRadius + "px"
        : "",
    });
  }

  static $style = css`
    .pill-button {
      padding: 4px 8px;
      font-size: 0.775rem;
      background-color: var(--pill-button-background, #fee2dd);
      border-radius: var(--pill-button-border-radius, 4px);
      width: fit-content;
      color: var(--pill-button-color, var(--black));
    }
  `;

  static $styles = [PillButton.$style];

  static $html = template`
    <div id=root class=pill-button style=white-space:nowrap;><slot></slot></div>
  `;
}

export namespace PillButton {
  export type Options = Optional<{
    color: string;
    background: string;
    borderRadius: number;
    margin: string;
    padding: string;
  }>;
}
