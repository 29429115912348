import { LonaWebComponent, template } from "../component";
import { Center, Div } from "../component-builtin";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { DomUtils } from "../dom";
import { Typography } from "../ui/typography";

@component({
  name: "std-avatar",
})
export class Avatar extends LonaWebComponent {
  static makeWith(): Avatar {
    const $a = Avatar.make();
    const $profile = Div.make();
    $profile.style.background = "var(--red)";
    $a.appendChild($profile);
    return $a;
  }

  static makeWithInitials(initials: string, options?: Avatar.Options): Avatar {
    const $a = Avatar.make();
    $a.bindInitials(initials, options);
    return $a;
  }

  bindInitials(initials: string, options?: Avatar.Options) {
    DomUtils.clearChildren(this);
    this.setAttribute("size", options?.size ?? "");

    const $c = Center.make();
    DomUtils.assignStyles($c, {
      background: options?.backgroundColor ?? "var(--selected-color)",
      color: options?.color ?? "",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    });
    const $profile = Typography.p(initials);
    $c.appendChild($profile);

    this.appendChild($c);
  }

  static makeWithImage(url: string): Avatar {
    const $a = Avatar.make();
    const $profile = new Image();
    $profile.src = url;
    $a.appendChild($profile);
    return $a;
  }

  static $styles = [
    css`
      :host([size="16"]) {
        --_dim: 16px;
      }
      :host([size="20"]) {
        --_dim: 20px;
      }
      :host([size="24"]) {
        --_dim: 24px;
      }
      :host([size="32"]) {
        --_dim: 32px;
      }
      slot::slotted(*) {
        box-sizing: border-box;
        border-radius: 50%;
        height: var(--_dim, 24px);
        width: var(--_dim, 24px);
      }
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <slot></slot>
  `;
}

export namespace Avatar {
  export type Options = Option<
    Optional<{
      backgroundColor: string;
      size: "16" | "20" | "24" | "32";
      color: string;
    }>
  >;
}
