import { Popover } from "./popover";
import { Constants } from "../constants";
import { DomUtils } from "../dom";
import { Point } from "../point";
import { LonaIcons } from "../ui/icons";
import { GESTURE_MANAGER } from "../gesture-manager";
import { ListCellLayout } from "./list/list-cell-layout";
import { Typography } from "../ui/typography";
import { $$ } from "../fastdom";

export namespace ContextMenu {
  let $currentContextMenu: Option<HTMLElement>;

  export function builder(): Builder {
    return new Builder();
  }

  function show(
    $contextMenu: HTMLElement,
    cursorPosition: Point,
    cleanup: EmptyFunction,
    $root: HTMLElement = document.body
  ): () => void {
    if ($currentContextMenu) {
      $currentContextMenu.parentElement?.removeChild($currentContextMenu);
      $currentContextMenu = null;
    }

    const dismiss = () => {
      $currentContextMenu?.parentElement?.removeChild($currentContextMenu);
      $currentContextMenu = null;
      cleanup();
    };

    GESTURE_MANAGER.toggleCleanUpOnClickOutside($contextMenu, dismiss);
    $contextMenu.style.position = "absolute";
    $contextMenu.style.top = cursorPosition.y + "px";
    $contextMenu.style.left = cursorPosition.x + "px";
    $contextMenu.style.zIndex = String(99999999);
    $$.mutate(() => {
      $root.appendChild($contextMenu);
    });

    $currentContextMenu = $contextMenu;
    return dismiss;
  }

  class Builder {
    private $popover: Popover;
    private dismiss: EmptyFunction = Constants.EMPTY_FUNCTION;

    constructor() {
      const $popover = Popover.make();
      $popover.style.setProperty("--popover-padding", "4px");
      $popover.style.width = "200px";
      this.$popover = $popover;
    }

    row($e: HTMLElement): this {
      this.$popover.appendChild($e);
      return this;
    }

    cellRow(
      t: string,
      icon: Option<LonaIcons.RawSvg>,
      action: Option<(state: { shouldDismiss: boolean }) => void>,
      iconColor: Option<string> = null
    ): this {
      const $cell = ListCellLayout.makeWith({
        $icon: icon
          ? LonaIcons.make(icon, {
              color: iconColor,
              margin: "0px 6px 0px 0px",
            })
          : null,
        $content: DomUtils.assignStyles(Typography.p(t), {
          "--margin-top": "3px",
          "--margin-bottom": "3px",
        }),
      });
      if (action) {
        $cell.addPointerEvent({
          onClick: () => {
            const state = {
              shouldDismiss: true,
            };
            action(state);
            if (state.shouldDismiss) this.dismiss();
          },
        });
        $cell.style.cursor = "pointer";
      } else {
        $cell.style.setProperty(
          "--list-cell-layout-hover-color",
          "transparent"
        );
      }
      this.$popover.appendChild($cell);
      return this;
    }

    build(config: { onDismiss: EmptyFunction }): Popover {
      this.dismiss = config.onDismiss;
      return this.$popover;
    }

    buildAndReveal(
      $target: HTMLElement,
      p: Point,
      options?: Optional<{
        cleanup: EmptyFunction;
        $root: HTMLElement;
      }>
    ) {
      $target.toggleAttribute("context-menu-open", true);
      this.dismiss = show(
        this.$popover,
        p,
        () => {
          $target.toggleAttribute("context-menu-open", false);
          options?.cleanup && options?.cleanup();
        },
        options?.$root ?? document.body
      );
    }
  }
}
