import { StoryBook } from "@lona/ui/doc-example";
import { GESTURE_MANAGER } from "@lona/gesture-manager";
import { Typography } from "@lona/ui/typography";
import { Toast } from "./toast";
import { $jsx } from "../../ui/jsx";

export class ToastStoryBook extends StoryBook.Default {
  title(): string {
    return "Toast";
  }

  examples(): StoryBook.Example[] {
    return [
      {
        render: () => {
          const $container = $jsx("std-col");

          const $toast = Toast.makeWith({
            title: "You've got mail",
          });
          $container.appendChild($toast);

          {
            const $t = $jsx(
              "button",
              {
                style: {
                  width: "100%",
                  maxWidth: "200px",
                  marginTop: "8px",
                  marginBottom: "2px",
                },
              },
              Typography.p("Show Toast")
            );
            $t.toggleAttribute("flat", true);

            GESTURE_MANAGER.addPointerEvent($t, {
              onClick: () => {
                const r = Math.floor(Math.random() * 4);
                [
                  () =>
                    Toast.info("You earned a cake!", {
                      description:
                        "Cake was very delicious. It had lots of fruit.",
                    }),
                  () =>
                    Toast.info("Deleted something!", {
                      undo: () => {},
                    }),
                  () => Toast.error("Uh oh! Something went wrong"),
                  () => Toast.warn("Warning"),
                ][r]();
              },
            });
            $container.appendChild($t);
          }
          {
            const $t = $jsx(
              "button",
              {
                style: {
                  width: "100%",
                  maxWidth: "200px",
                },
              },
              Typography.p("Throw Exception")
            );
            $t.toggleAttribute("flat", true);
            GESTURE_MANAGER.addPointerEvent($t, {
              onClick: () => {
                throw new Error("wah wah");
              },
            });
            $container.appendChild($t);
          }

          return $container;
        },
      },
    ];
  }
}
