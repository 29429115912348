import { DocExample } from "@lona/ui/doc-example";
import { LonaWebComponent, template } from "../../component";
import { AuthPage } from "./auth-page";

const example: DocExample<AuthPage> = {
  factory: LonaWebComponent.asFactory(AuthPage),
  componentName: "Date Picker",
  bind: ($c: AuthPage) => {
    $c.style.height = "840px";

    const $title = template`
      <std-col slot="title" style=align-self:start;>
        <std-row style=margin-left:-28px;>
          <std-sheets-icon style=margin-right:8px;></std-sheets-icon>
          <h1 id=title class=h2 style="--margin-bottom:12px">Lona</h1>
        </std-row>
        <h2 id=subtitle class=h4 style="color:var(--tertiary-text-color);font-weight:400;">Calendar, Tasks, Sheets. All in one.</h1>
        <p style=color:var(--secondary-text-color);display:none;>Calendar. Notes. Tasks.</p>
      </std-col>
    `;
    $c.appendChild($title.content.cloneNode(true));

    const $button = template`
      <button slot=button primary style=width:100%;max-width:600px;>Get Started</button>
    `;
    $c.appendChild($button.content.cloneNode(true));

    const $privacyPolicy = template`
      <a slot="privacy-policy" href="#">Privacy Policy</a>
    `;
    $c.appendChild($privacyPolicy.content.cloneNode(true));

    const $tos = template`
      <a slot="tos" href="#">Terms of Service</a>
    `;
    $c.appendChild($tos.content.cloneNode(true));
  },
};

export default example;
