import { StoryBook } from "../ui/doc-example";
import { $jsx } from "../ui/jsx";
import { FlowContainer } from "./flow-container";
import { Form } from "./input/form";
import { SidebarSectionNav } from "./sidebar/sidebar-section-nav";

export class FlowContainerStoryBook extends StoryBook.Default {
  title(): string {
    return "Flow Container";
  }

  examples(): StoryBook.Example[] {
    return [
      {
        render: () => {
          const back = () => {
            $nav.toggleAttribute("back-button-shown", false);
            $c.popPage();
          };

          const $secondPage = $jsx(
            "div",
            {
              style: {
                height: "150px",
                background: "var(--orange)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
              },
              pointerEvents: {
                onClick: back,
              },
              slot: "page",
            },
            "Success!"
          );

          const $nav = SidebarSectionNav.make().bind("Title", {
            onBackButton: back,
          });
          const $c = FlowContainer.make()
            .setHeader($nav)
            .pushPage(
              Form.builder()
                .input({
                  name: "name",
                  label: "First Name",
                })
                .input({
                  name: "email",
                  label: "Email",
                })
                .input({
                  name: "phone-number",
                  label: "Phone Number",
                })
                .submit({
                  label: "Continue",
                  onClick: () => {
                    $nav.toggleAttribute("back-button-shown", true);
                    $c.pushPage($secondPage);
                  },
                })
                .build()
                .assignStyles({
                  padding: "8px",
                })
            );

          const $container = $jsx("div", {
            style: {
              position: "relative",
              height: "320px",
              width: "100%",
              maxWidth: "400px",
            },
          });
          $container.appendChild($c);
          $c.assignStyles({
            position: "absolute",
            top: "0px",
            left: "0px",
          });

          return $container;
        },
      },
    ];
  }
}
