import { Local } from "@lona-chrono";
import { StoryBook } from "../ui/doc-example";
import { Streaks } from "./streaks";

const FAKE_STREAKS = [
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 1, 0],
  [0, 0, 0, 0, 0, 1, 0],
  [0, 0, 1, 2, 0, 0, 0],
  [0, 2, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 1, 1, 2, 4, 0, 0],
  [0, 0, 2, 4, 3, 0, 0],
  [0, 0, 0, 4, 3, 1, 1],
  [1, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
];

export class StreaksStoryBook extends StoryBook.Default {
  title(): string {
    return "Streaks";
  }

  examples(): StoryBook.Example[] {
    return [
      {
        render: () => {
          const today = Local.today;

          let i = 0;
          const $streaks = Streaks.make();
          $streaks.bind({
            today,
            durationMths: 4,
            value(date) {
              // return date.utc.getDayOfWeek();
              const v = FAKE_STREAKS[Math.floor(i / 7)][i % 7];
              i++;
              return v;
            },
          });

          return $streaks;
        },
      },
    ];
  }
}
