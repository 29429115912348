import { LonaWebComponent } from "../component";
import { Div } from "../component-builtin";
import { LiveData } from "../livedata";
import { DocExample } from "../ui/doc-example";
import { Scroll } from "./scroll";

const example: DocExample<Scroll<HTMLDivElement, HTMLDivElement>> = {
  factory: LonaWebComponent.asFactory(Scroll) as unknown as Factory<
    Scroll<HTMLDivElement, HTMLDivElement>
  >,
  componentName: "Scroll",
  bind: ($c: Scroll<HTMLDivElement, HTMLDivElement>) => {
    $c.style.height = "200px";
    // NOTE: setting a width here is necessary - this component
    // doesn't know how to lay itself out
    $c.style.width = "200px";
    $c.style.display = "block";
    $c.style.background = "var(--background-color)";
    $c.style.borderRadius = "8px";
    $c.initialize({
      onCreate: () => ({
        $header: Div.make(),
        $body: Div.make(),
      }),
      onBind: (
        $e: { $header: HTMLDivElement; $body: HTMLDivElement },
        index: number,
        widthPx: number
      ) => {
        $e.$header.textContent = String(index);
        $e.$header.style.height = "40px";
        $e.$body.style.height = "200px";
      },
      cellWidthPx: 30,
      headerHeight: 40,
      snappingEnabled: false,
    });
  },
};

export default example;
