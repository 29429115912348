import { dev } from "../log";
import { StoryBook } from "../ui/doc-example";
import { NestedList } from "./nested-list";

const EXAMPLE_ROWS: NestedList.Row[] = [
  {
    id: "1",
    label: "hello",
    children: [
      {
        id: "1.1",
        label: "world",
      },
      {
        id: "1.2",
        label: "blah",
        children: [
          {
            id: "1.2.1",
            label: "nested",
          },
        ],
      },
    ],
  },
  {
    id: "2",
    label: "Next",
  },
  {
    id: "3",
    label: "Next",
  },
];

export class NestedListStoryBook extends StoryBook.Default {
  title(): string {
    return "Nested List";
  }

  examples(): StoryBook.Example[] {
    return [
      {
        render: () => {
          const $nested = NestedList.makeRoot(EXAMPLE_ROWS);
          $nested.style.width = "160px";
          dev($nested);
          return $nested;
        },
      },
    ];
  }
}
