import { LonaWebComponent } from "../component";
import { Div } from "../component-builtin";
import { dev } from "../log";
import { DocExample } from "../ui/doc-example";
import { Carousel } from "./carousel";
import { PageIndiciatorDots } from "./page-indicator-dots";

const example: DocExample<Carousel> = {
  factory: LonaWebComponent.asFactory(Carousel),
  componentName: "Carousel",
  bind: ($c: Carousel, $container: HTMLElement) => {
    const $indicatorDots = PageIndiciatorDots.make();
    $indicatorDots.slot = "component";
    $indicatorDots.bind(0, 2);
    $indicatorDots.style.marginTop = "4px";

    $c.style.height = "120px";
    $c.domBind([Div.makeText("hello"), Div.makeText("world")], 2, {
      onScroll: (from, to) => {
        $indicatorDots.bindSelectedIndex(to);
      },
    });

    $container.appendChild($indicatorDots);
  },
};

export default example;
