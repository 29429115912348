export type _AbstractRange<T> = { start: T; end: T };

export type RFC3339Range = _AbstractRange<RFC3339>;
export type NumRange = _AbstractRange<number>;
export type NumOptRange = _AbstractRange<Option<number>>;

export namespace NumRange {
  export function make(a: number[]): NumRange {
    return {
      start: a[0],
      end: a[1],
    };
  }

  export function containsRange(larger: NumRange, smaller: NumRange): boolean {
    return (
      NumRange.contains(larger, smaller.start) &&
      NumRange.contains(larger, smaller.end)
    );
  }

  export function intersects(r1: NumRange, r2: NumRange): boolean {
    return r1.start <= r2.end && r2.start <= r1.end;
  }

  export function contains(s: NumRange, n: number): boolean {
    return n >= s.start && n <= s.end;
  }

  export function containsExclusiveEnd(s: NumRange, n: number): boolean {
    return n >= s.start && n < s.end;
  }

  export function intervalUnionArea(intervals: NumRange[]): number {
    if (intervals.length == 0) return 0;
    if (intervals.length == 1) return intervals[0].end - intervals[0].start;
    intervals.sort((a, b) => a.start - b.start);
    let currentStart = intervals[0].start;
    let currentEnd = intervals[0].end;
    let totalArea = 0;
    for (let i = 1; i < intervals.length; i++) {
      const interval = intervals[i];
      if (interval.start > currentEnd) {
        totalArea += currentEnd - currentStart;
        currentStart = interval.start;
        currentEnd = interval.end;
      } else {
        currentEnd = Math.max(currentEnd, interval.end);
      }
    }
    totalArea += currentEnd - currentStart;
    return totalArea;
  }

  export function progress(pct: number, range: NumRange): number {
    return range.start + pct * (range.end - range.start);
  }

  export function getProgressPct(progress: number, range: NumRange): number {
    return (progress - range.start) / (range.end - range.start);
  }
}
