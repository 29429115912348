import { DateTime, Local, TimeUnit } from "@lona-chrono";
import { DocExample } from "../../ui/doc-example";
import { EventDetailsTimeLabel } from "./event-details-time-label";

const example: DocExample<EventDetailsTimeLabel> = {
  factory: () => EventDetailsTimeLabel.make(),
  componentName: "Event Details Time Label",
  bind: ($c: EventDetailsTimeLabel) => {
    const start = Local.now().nearest(TimeUnit.MIN15);
    $c.bind(
      new DateTime.Range(
        start,
        start.add({
          hrs: 8,
        })
      )
    );
  },
};

export default example;
