import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { Constants } from "../constants";
import { DomUtils, getPropertyValueDefault } from "../dom";
import { $_maybe, $qsa } from "../dom-selectors";
import { $$, mutate } from "../fastdom";
import { LazySync } from "../lazy";
import { LiveData } from "../livedata";
import { dev, info } from "../log";
import { NumRange } from "../range";
import { Popover } from "./popover";
import { ScrollEngine } from "./scroll-engine";

export type Inset = {
  top: number;
  left: number;
  right: number;
  bottom: number;
};

export type PopoverRevealOptions = Optional<{
  edge?: Option<{
    top: number;
    bottom: number;
  }>;
  offset?: Optional<{
    xPx: number;
    yPx: number;
  }>;
  widthPx: number;
  shouldOverlapIndex: boolean;
  onBind: EmptyFunction;
  onDismiss: EmptyFunction;
}>;

type ScrollConfig<Header extends HTMLElement, Body extends HTMLElement> = {
  onCreate: () => { $header: Header; $body: Body };
  onBind: (
    $e: { $header: Header; $body: Body },
    index: number,
    widthPx: number,
    resizeOnly: boolean
  ) => void;

  cellWidthPx: number;
  headerHeight: number;
  getCellWidthForIndex?: (index: number) => Option<number>;

  snappingEnabled: boolean;

  onVisibleIndiciesChanged?: (visibleIndicies: NumRange) => void;
  onRenderedIndiciesChanged?: (renderedIndicies: NumRange) => void;
  onRenderedIndiciesWillChange?: (renderedIndicies: NumRange) => void;
  onScroll?: Option<(config: { left: number; top: number }) => void>;
};

@component({
  name: "std-scroll",
})
export class Scroll<
  Header extends HTMLElement,
  Body extends HTMLElement
> extends LonaWebComponent {
  scrollEngine: ScrollEngine<{ $header: Header; $body: Body }> =
    new ScrollEngine();
  private config: Option<ScrollConfig<Header, Body>>;
  private currentHeaderHeightPx: number = 0;

  private $contentViewport = this.$("content-viewport");

  initialize(config: ScrollConfig<Header, Body>) {
    this.config = config;

    //
    // NOTE: Getting the initial size (sidebarWidth) takes 1 frame to queue.
    // We need to wait for the second animation frame to properly get the
    // cellWidth
    //
    info("ui")("sheet/layout/init-queued", $$.getState());
    $$.defer(() => {
      $$.mutateEnd(() => {
        info("ui")("sheet/layout/init");
        this.setHeaderPeekHeight(config.headerHeight, false);
        this.scrollEngine.initialize(this.$contentViewport, {
          onCreate: () => {
            const { $header, $body } = config.onCreate();
            $header.slot = "header";
            $body.slot = "body";
            $$.mutate(() => {
              this.appendChild($header);
              this.appendChild($body);
            });
            return { $header, $body };
          },
          defaultCellWidthPx: config.cellWidthPx,
          onBind: (
            { $header, $body },
            index,
            { scrollPositionPx, baseOffsetPx },
            widthPx,
            offsetChangedOnly
          ) => {
            if (!offsetChangedOnly) {
              config.onBind(
                { $header, $body },
                index,
                widthPx,
                offsetChangedOnly
              );
            }
            const zIndex = index + Math.floor(Constants.MAX_Z_INDEX / 2);
            $$.mutate(() => {
              $header.setAttribute("dbg-idx", String(index));
              $header.style.transform = `translate(${
                baseOffsetPx + scrollPositionPx
              }px, var(--y, 0px))`;
              $header.style.width = widthPx + "px";
              $header.style.top = "0px";
              $header.style.zIndex = String(zIndex);
              $header.style.removeProperty("opacity");
              $header.style.removeProperty("pointer-events");
              // $header.style.display = "block";

              $body.style.transform = `translateX(${scrollPositionPx}px)`;
              $body.style.top = "0px";
              $body.style.left = baseOffsetPx + "px";
              $body.style.width = widthPx + "px";
              $body.style.zIndex = String(zIndex);
              $body.style.removeProperty("opacity");
              $body.style.removeProperty("pointer-events");
              // $body.style.display = "block";
            });
          },
          onRecycle: ({ $header, $body }) => {
            $$.mutate(() => {
              $header.style.opacity = "0";
              $header.style.pointerEvents = "none";
              $body.style.opacity = "0";
              $body.style.pointerEvents = "none";
            });
          },
          onRenderedIndiciesWillChange: config.onRenderedIndiciesWillChange,
          onRenderedIndiciesChanged: config.onRenderedIndiciesChanged,
          onVisibleIndiciesChanged: (visibleIndicies) => {
            if (!this.config?.onVisibleIndiciesChanged) return;
            $$.mutate(() =>
              this.config?.onVisibleIndiciesChanged!(visibleIndicies)
            );
          },
          onScroll: (p) => {
            // if (previousTop == top) return;
            // for (const it of this.scrollEngine.entries) {
            //   it.t.$header.style.setProperty("--y", top + "px");
            // }
            // previousTop = top;
            config.onScroll && config.onScroll(p);
          },
          cellWidthForIndex: (index: number) =>
            this.config!.getCellWidthForIndex
              ? this.config!.getCellWidthForIndex(index)
              : null,
          snappingEnabled: config.snappingEnabled,
        });
      });
    });
  }

  /*
   APIs
   =============================================================================
  */
  toggleBackground(force?: Option<boolean>, animate: boolean = true) {
    this.$("headers-bg").toggleAttribute("no-transition", !animate);
    this.toggleAttribute("fullscreen-bg", force ?? undefined);
  }

  getBackgroundHeight(): number {
    return parseInt(
      this.$("headers-bg").style.getPropertyValue("--headers-bg-peek")
    );
  }

  toggleHeaderVisibility(force?: Option<boolean>) {
    this.$("headers").toggleAttribute("opacity-hidden", !force);
  }

  toggleHeaderShadow(force: boolean) {
    this.toggleAttribute("header-shadow-hidden", !force);
  }

  setHeaderPeekHeight(height: number, animate: boolean = false) {
    this.$("headers-bg").toggleAttribute("no-transition", !animate);
    $$.mutate(() => {
      this.$("headers-bg").style.setProperty(
        "--headers-bg-peek",
        height + "px"
      );
      this.$("headers").style.height = height + "px";
    });
    if (height != this.currentHeaderHeightPx) {
      this.currentHeaderHeightPx = height;
    }
  }

  toggleContentVisibility(force?: Option<boolean>, animate: boolean = false) {
    this.style.setProperty("--content-viewport-visibility", force ? "1" : "0");
  }

  getEntryAtIndex(index: number): Option<{ $header: Header; $body: Body }> {
    return this.scrollEngine.getEntryAtIndex(index)?.t;
  }

  /*
   APIs (Sticky Child)
   =============================================================================
  */
  appendStickyChild($e: HTMLElement) {
    $e.slot = "sticky";
    $e.classList.add("sticky-child");
    $e.style.top = "0";
    $e.style.transform = "translateY(var(--additional-y, 0px))";
    $$.mutate(() => {
      this.appendChild($e);
    });
  }

  @mutate
  removeAllStickyChildren() {
    // NOTE: query based off the light dom
    this.$qsa(".sticky-child").forEach(($e) => DomUtils.removeFromDom($e));
  }

  /*
   APIs (Fixed Child)
   =============================================================================
  */
  private _appendFixedChild($e: HTMLElement) {
    $e.slot = "body";
    $e.classList.add("fixed-body");
    DomUtils.assignStyles($e, {
      position: "absolute",
      top: "0",
      left: ScrollEngine.scrollStart + "px",
      "--y-from-row-index": "var(--body-top-offset)",
      transform:
        "translate(calc(var(--x, 0px) + var(--additional-x, 0px)), calc(var(--y-from-row-index, 0px) + var(--y, 0px) + var(--additional-y, 0px)))",
      zIndex: String(Constants.MAX_Z_INDEX - 5),
    });
    this.appendChild($e);
  }

  appendFixedChild(
    $e: HTMLElement,
    position: {
      xIndex: number;
      yPx: LiveData<number>;
      offsetX?: Option<number>;
      offsetY?: Option<number>;
    },
    options?: Optional<{
      animate: boolean;
    }>
  ) {
    const edgePx = {
      left:
        this.scrollEngine.offsetForIndex(position.xIndex)! -
        this.scrollEngine.scrollOffset,
      bottom: 0,
      right: 0,
      top: 0,
    };
    const { size, spaceRemaining, canLayoutRight, canLayoutLeft } =
      this.calculateFixedChildSpaceRemaining($e, edgePx);

    dev({ canLayoutRight, canLayoutLeft, position });

    // todo: spaceRemaining is not done
    let additionalX = position.offsetX;
    // if (!canLayoutLeft) {
    //   additionalX = -size.width;
    // } else {
    //   additionalX = 0;
    // }

    // 3) Insert into layout
    const newPosition = this.setFixedChildPosition(
      $e,
      {
        ...position,
        offsetX: additionalX,
      },
      0,
      spaceRemaining,
      edgePx,
      size
    );

    dev({ newPosition });

    $$.mutate(() => {
      DomUtils.assignStyles($e, {
        transition: options?.animate
          ? "transform 0.3s ease, opacity 0.3s ease"
          : "",
        "--additional-x": newPosition.additionalX + "px",
        "--additional-y": newPosition.additionalY + "px",
        "--y": newPosition.y + "px",
      });
    });

    this._appendFixedChild($e);
  }

  findFixedChild<T extends HTMLElement>(id: string): Option<T> {
    return $_maybe<T>(id, this);
  }

  @mutate
  removeAllFixedBody() {
    // NOTE: query based off the light dom
    $qsa(".fixed-body", this).forEach(($e) => DomUtils.removeFromDom($e));
  }

  removeAllFixedBodySync() {
    // NOTE: query based off the light dom
    $qsa(".fixed-body", this).forEach(($e) => DomUtils.removeFromDom($e));
  }

  private calculateFixedChildSpaceRemaining(
    $e: HTMLElement,
    edgePx: Inset
  ): {
    spaceRemaining: Inset;
    size: Size;
    canLayoutLeft: boolean;
    canLayoutRight: boolean;
  } {
    const size = {
      width: $e.offsetWidth,
      height: $e.offsetHeight,
    };
    const spaceRemaining = {
      top: 999999,
      left: edgePx.left,
      // todo!!!!! Lona.sidebarWidthManager.visibleSidebarWidthPx - Lona.$$.sheet.legendWidthPx,
      right: window.innerWidth - edgePx.right,
      bottom:
        window.innerHeight -
        /* distanceToNavigationBar */ (edgePx.top - this.listScrollTop + 20),
    };
    return {
      size,
      spaceRemaining,
      canLayoutLeft: spaceRemaining.left > size.width,
      canLayoutRight: spaceRemaining.right > size.width,
    };
  }

  private setFixedChildPosition(
    $e: HTMLElement,
    position: {
      xIndex: number;
      yPx: LiveData<number>;
      offsetX?: Option<number>;
      offsetY?: Option<number>;
    },
    paddingTopPx: number,
    spaceRemaining: Inset,
    edgePx: Inset,
    size: Size
  ): {
    x: number;
    y: number;
    additionalX: number;
    additionalY: number;
  } {
    const offsetX = this.scrollEngine.setFixedChildIndex(
      $e,
      position.xIndex,
      ($e, offset) => {
        $$.mutate(() => $e.style.setProperty("--x", offset + "px"));
      }
    );
    position.yPx.addListener($e, (newAdditionalY) => {
      $e.style.setProperty(
        "--additional-y",
        newAdditionalY + (position.offsetY ?? 0) + "px"
      );
    });

    // 4) Generate new position
    return {
      additionalX: position.offsetX ?? 0,
      additionalY: Math.round(position.yPx.get()) + (position.offsetY ?? 0),
      y: Math.round(
        spaceRemaining.bottom < size.height
          ? edgePx.bottom - size.height + paddingTopPx
          : edgePx.top + paddingTopPx
      ),
      x: offsetX,
    };
  }

  /*
   Popover
   =============================================================================
  */
  private popoverPresented = false;
  private _popoverIdentifier: Option<string>;
  private onDismissPopover: EmptyFunction = Constants.EMPTY_FUNCTION;

  get popoverIdentifier(): Option<string> {
    return this._popoverIdentifier;
  }

  private $popover = new LazySync<Popover>(() => {
    const $e = Popover.make();
    $e.id = "popover";
    return $e;
  });

  revealPopover(
    identifier: string,
    $content: HTMLElement,
    position: {
      xIndex: number;
      yPx: LiveData<number>;
    },
    subcolumnWidth: number,
    options?: PopoverRevealOptions
  ) {
    this.layoutAndPresentPopover(
      identifier,
      $content,
      position,
      {
        left:
          this.scrollEngine.offsetForIndex(position.xIndex)! -
          this.scrollEngine.scrollOffset,
        bottom: 0,
        right: 0,
        top: 0,
      },
      subcolumnWidth,
      options?.widthPx ?? 340,
      options?.shouldOverlapIndex ?? false,
      options?.onBind ?? Constants.EMPTY_FUNCTION,
      options?.onDismiss ?? Constants.EMPTY_FUNCTION,
      options?.offset?.yPx ?? 0,
      options?.offset?.xPx ?? 0
    );
  }

  layoutAndPresentPopover(
    popoverIdentifier: string,
    $popoverContents: HTMLElement,
    position: {
      xIndex: number;
      yPx: LiveData<number>;
    },
    edgePx: Inset,
    subcolumnWidth: number,
    // options
    widthPx: number = 340,
    overlapIndex: boolean = false,
    bind: EmptyFunction = Constants.EMPTY_FUNCTION,
    onDismiss: EmptyFunction = Constants.EMPTY_FUNCTION,
    paddingTopPx: number = 0,
    paddingLeftPx: number = 0
  ) {
    this.onDismissPopover();
    console.log("POPOVER", position.xIndex, paddingLeftPx);

    this._popoverIdentifier = popoverIdentifier;
    this.onDismissPopover = onDismiss;

    // 1) Perform the bind and then grab the offsetHeight/Width so we know
    // how we should lay this out
    const $e = this.$popover.get();
    this._appendFixedChild($e);
    $e.replaceChildren($popoverContents);
    bind();
    requestAnimationFrame(() => {
      $e.style.width = widthPx + "px";
      $e.style.pointerEvents = "all";

      // 2) Get current layout information
      const { spaceRemaining, size, canLayoutLeft, canLayoutRight } =
        this.calculateFixedChildSpaceRemaining($e, edgePx);

      // 2) See if we need to layout left or right
      let additionalX = paddingLeftPx;
      if (overlapIndex) {
        console.log("POPOVER-overlap");
        additionalX = paddingLeftPx;
      } else if (!canLayoutLeft && !canLayoutRight) {
        console.log("POPOVER-no-space");
        paddingTopPx += 18;
        paddingLeftPx += 18;
        additionalX = paddingLeftPx;
      } else if (canLayoutLeft) {
        console.log("POPOVER-left");
        additionalX = -size.width - 8 + paddingLeftPx;
      } else {
        console.log(
          "POPOVER-right",
          position.xIndex,
          paddingLeftPx,
          subcolumnWidth
        );
        additionalX = paddingLeftPx + subcolumnWidth;
      }
      console.log("POPOVER", canLayoutLeft, canLayoutRight);

      // 3) Insert into layout
      const newPosition = this.setFixedChildPosition(
        $e,
        {
          ...position,
          offsetX: additionalX,
        },
        paddingTopPx,
        spaceRemaining,
        edgePx,
        size
      );

      // 3) Calculate distance to previous position
      let dist2 = 0;
      const previous = {
        x:
          parseInt(getPropertyValueDefault($e, "--x", "0px")) +
          parseInt(getPropertyValueDefault($e, "--additional-x", "0px")),
        y:
          parseInt(getPropertyValueDefault($e, "--y", "0px")) +
          parseInt(getPropertyValueDefault($e, "--additional-y", "0px")) +
          this.getBackgroundHeight(),
      };

      if (previous.x && previous.y) {
        dist2 =
          (newPosition.x + newPosition.additionalX - previous.x) ** 2 +
          (newPosition.y +
            newPosition.additionalY +
            this.getBackgroundHeight() -
            previous.y) **
            2;
      }

      $e.style.transition = "none";
      if (!this.popoverPresented || dist2 > 280_000) {
        DomUtils.assignStyles($e, {
          transition: "none",
          opacity: String(0),
          "--additional-x": newPosition.additionalX + "px",
          "--additional-y": newPosition.additionalY + "px",
          "--y": newPosition.y + 40 + "px",
        });
        $$.measure(() => {
          $e.offsetHeight;
          setTimeout(() => {
            $$.mutate(() => {
              DomUtils.assignStyles($e, {
                transition: "transform 0.3s ease, opacity 0.3s ease",
                opacity: String(1),
                "--y": newPosition.y + "px",
              });
            });
          });
        });
      } else {
        $$.mutate(() => {
          DomUtils.assignStyles($e, {
            transition: "transform 0.3s ease, opacity 0.3s ease",
            "--additional-x": newPosition.additionalX + "px",
            "--additional-y": newPosition.additionalY + "px",
            "--y": newPosition.y + "px",
          });
        });
      }

      this.popoverPresented = true;
    });
  }

  dismissPopover(popoverIdentifier: string) {
    if (!this.popoverPresented) return;
    if (this._popoverIdentifier != popoverIdentifier) return;
    this.onDismissPopover();
    this._popoverIdentifier = undefined;
    this.popoverPresented = false;
    this.onDismissPopover = Constants.EMPTY_FUNCTION;
    const $popover = this.$popover.get();
    const previousY = parseInt($popover.style.getPropertyValue("--y"));
    $popover.style.setProperty("--y", previousY + 40 + "px");
    $popover.style.opacity = String(0);
    $popover.style.pointerEvents = "none";
    $popover.ontransitionend = async () => {
      $popover.style.setProperty("--height", "100%");
      $popover.ontransitionend = null;
    };
  }

  currentPopoverIdentifier(): Option<string> {
    return this._popoverIdentifier;
  }

  /*
   Scroll Top
   =============================================================================
  */
  set listScrollTop(top: number) {
    this.$("content-viewport").scrollTop = top;
  }

  get listScrollTop(): number {
    return this.$("content-viewport").scrollTop;
  }

  static $styles = [
    css`
      :host {
        --max-z: 16777271;
        --max-width: 16777200px;
        --content-height: var(--std-scroll-content-height);
      }
      [no-transition] {
        transition: none !important;
      }

      :host([shadow-protection]) .shadow-protection {
        content: "";
        position: sticky;
        top: -1px;
        bottom: 0px;
        width: 0px;
        height: calc(100% + 2px);
        background: var(--background-color-elevated, white);
        z-index: var(--max-z);
      }
      :host([shadow-protection]) .shadow-protection[left] {
        left: -1;
        box-shadow: 2px 0px 4px 4px var(--background-color-elevated, white);
      }
      :host([shadow-protection]) .shadow-protection[right] {
        left: calc(100% + 1px);
        box-shadow: -2px 0px 4px 4px var(--background-color-elevated, white);
        transform: translateY(-100%);
      }
    `,
    css`
      #content-viewport:not([show-scrollbar]) {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
      #content-viewport:not([show-scrollbar])::-webkit-scrollbar {
        display: none;
      }
      :host([scroll-cell-transitions]) slot[name="header"]::slotted(*),
      :host([scroll-cell-transitions]) slot[name="body"]::slotted(*) {
        transition: transform 0.3s ease;
      }
      slot[name="header"]::slotted(*),
      slot[name="body"]::slotted(*) {
        transition: none;
      }
      #headers,
      #sidebar {
        transition: opacity 0.3s ease;
      }
    `,
    css`
      #headers-bg {
        position: absolute;
        top: 0;
        left: -4px;
        z-index: 1;
        height: calc(100% + 8px);
        width: calc(100% + 8px);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        transform: translateY(
          calc(
            -100% + var(--headers-bg-peek, 0px) + var(--headers-bg-peek-padding, 0px)
          )
        );
        box-shadow: 0px 0px 6px 3px rgb(0 0 0 / 12%);
        background: var(--background-color-elevated, white);
        border-radius: 8px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        z-index: var(--max-z);
        user-select: none;
      }
      :host([header-shadow-hidden]) #headers-bg {
        box-shadow: none;
      }
      :host([fullscreen-bg]) #headers-bg {
        transform: translateY(-8px);
      }
    `,
    css`
      slot::slotted(*) {
        position: absolute;
      }
      :host {
        overflow: hidden;
      }
      #root {
        height: 100%;
        width: 100%;
        position: relative;
      }
      #content-viewport {
        height: 100%;
        width: 100%;
        overflow: scroll;
        box-sizing: border-box;
      }
      #content {
        position: relative;
        height: var(--content-height);
        width: var(--max-width);
      }
      #headers {
        position: sticky;
        top: 0px;
        z-index: var(--max-z);
        background: var(--background-color-elevated, white);
      }
      #header-sidebar {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: calc(var(--max-z) - 0);
      }
      #sidebar {
        position: sticky;
        left: 0px;
        z-index: calc(var(--max-z) - 1);
        background: var(--background-color, white);
        width: fit-content;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <div id=root>
      <div id=headers-bg></div>
      <div id=content-viewport>
        <div id=content>
          <div id=headers>
            <slot name=header></slot>
            <div class=shadow-protection left></div>
            <div class=shadow-protection right></div>
          </div>
          <div id=bodies>
            <slot name=body></slot>
          </div>
          <div id=sidebar>
            <slot name=sidebar></slot>
          </div>
        </div>
        <div id=header-sidebar>
          <slot name=header-sidebar></slot>
        </div>
      </div>
    </div>
  `;
}
