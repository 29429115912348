import { LonaWebComponent } from "../component";
import { DocExample } from "../ui/doc-example";
import { PillButton } from "./pill-button";

const example: DocExample<PillButton> = {
  factory: LonaWebComponent.asFactory(PillButton),
  componentName: "Page Indicator Dots",
  bind: ($c: PillButton) => {
    $c.bind("#work", {
      background: "var(--pink)",
    });
  },
};

export default example;
