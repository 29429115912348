import { StoryBook } from "../ui/doc-example";
import { ProseMirrorEditor } from "./prosemirror";

const TEST_MARKDOWN = `
# h1 Heading
## h2 Heading
### h3 Heading
#### h4 Heading
##### h5 Heading
###### h6 Heading
___

- bullet
- bullet 2

**This is bold text**

__This is bold text__

*This is italic text*

_This is italic text_


> Blockquotes can also be nested...
>> ...by using additional greater-than signs right next to each other...
> > > ...or with spaces between arrows.

Inline \`code\`

Indented code

    // Some comments
    line 1 of code
    line 2 of code
    line 3 of code
`;

// ----- Not Implemented -----

// Strikethrough
// ~~Strikethrough~~

// ----- Broken -----

// Images
// ![Minion](https://octodex.github.com/images/minion.png)

export class ProseMirrorStoryBook extends StoryBook.Default {
  title(): string {
    return "Prosemirror";
  }

  examples(): StoryBook.Example[] {
    return [
      {
        render: () => {
          const $editor = ProseMirrorEditor.make();
          $editor.assignStyles({
            height: "200px",
            borderRadius: "8px",
            background: "var(--card-background-color)",
          });
          $editor.bind();
          return $editor;
        },
      },
      {
        render: () => {
          const $editor = ProseMirrorEditor.make();
          $editor.assignStyles({
            height: "200px",
            borderRadius: "8px",
            background: "var(--card-background-color)",
          });
          $editor.bind({
            initialMarkdown: TEST_MARKDOWN,
          });
          return $editor;
        },
      },
    ];
  }
}
