import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { Constants } from "../constants";
import { DevTools } from "../dev-tools";
import { DomUtils } from "../dom";
import { $$ } from "../fastdom";
import { Lazy } from "../lazy";
import { dev } from "../log";

type ProseMirrorImport = typeof import("prosemirror-plugin");

@component({
  name: "std-prosemirror",
})
export class ProseMirrorEditor extends LonaWebComponent {
  private static import: Lazy<ProseMirrorImport> = new Lazy(
    () =>
      import(
        `https://static.lona.so/js/prosemirror.min.js?${DevTools.queryParam}`
      )
  );

  static async load(): Promise<void> {
    await ProseMirrorEditor.import!.get();
  }

  private $editor = this.$("editor");
  private view: Option<import("prosemirror-plugin").EditorView>;

  constructor() {
    super();
    this.onkeydown = (e) => {
      const hotkey = DomUtils.hotkeyIdentifier(e);
      if (e.key == "Tab") {
        e.stopImmediatePropagation();
        e.preventDefault();
      }
      if (!e.ctrlKey) {
        e.stopPropagation();
      }
      if (hotkey == "ctrl-s" || hotkey == "ctrl-c" || hotkey == "ctrl-v") {
        e.stopPropagation();
      }
    };
    this.onkeyup = (e) => {
      const hotkey = DomUtils.hotkeyIdentifier(e);
      if (e.key == "Tab") {
        e.stopImmediatePropagation();
        e.preventDefault();
      }
      if (!e.ctrlKey) {
        e.stopPropagation();
      }
      if (hotkey == "ctrl-s" || hotkey == "ctrl-c" || hotkey == "ctrl-v") {
        e.stopPropagation();
      }
    };
  }

  private currentMarkdown: Option<string>;
  private delegate: (md: string) => void = Constants.EMPTY_FUNCTION;
  private onUpdateMd(md: string) {
    const mdChanged = this.currentMarkdown != md;
    this.currentMarkdown = md;
    if (mdChanged) {
      this.delegate(md);
    }
  }
  private editable: boolean = false;

  async bind(
    {
      onUpdate,
      initialMarkdown,
      editable,
    }: Optional<{
      onUpdate: (markdown: string) => void;
      initialMarkdown: string;
      editable: boolean;
    }> = {
      onUpdate: null,
      initialMarkdown: null,
      editable: true,
    }
  ) {
    const prosemirror: ProseMirrorImport =
      await ProseMirrorEditor.import!.get();
    const { LonaProseMirror, EditorState, EditorView } = prosemirror;

    this.delegate = onUpdate ?? Constants.EMPTY_FUNCTION;
    const mdChanged = this.currentMarkdown != initialMarkdown;
    this.currentMarkdown = initialMarkdown;
    this.editable = editable != null ? editable : true;
    this.toggleAttribute("disabled", !this.editable);

    if (!mdChanged && this.view) {
      return;
    }

    if (this.view) {
      DomUtils.clearChildren(this.$editor);
      const oldView = this.view;
      $$.mutate(() => oldView.destroy());
    } else {
      $$.mutate(() => {
        this.adoptStyle(css(LonaProseMirror.style()));
      });
    }

    const { Markdown, Plugins } = LonaProseMirror;
    this.view = new EditorView(
      ($root: HTMLElement) => {
        $$.mutate(() => this.$editor.appendChild($root));
      },
      {
        dispatchTransaction: (transaction) => {
          const state = this.view!.state.apply(transaction);
          this.onUpdateMd(
            LonaProseMirror.Markdown.serializer.serialize(state.doc)
          );
          this.view!.updateState(state);
        },
        state: EditorState.create({
          doc: initialMarkdown
            ? Markdown.parser.parse(initialMarkdown) ?? undefined
            : undefined,
          schema: Markdown.schema,
          plugins: [
            Plugins.inputRules(Markdown.schema),
            Plugins.history(),
            Plugins.keymap(Plugins.keymapDefault(Markdown.schema)),
            Plugins.keymap({ "Mod-z": Plugins.undo, "Mod-y": Plugins.redo }),
            Plugins.keymap(Plugins.keymapBase),
            Plugins.dropCursor(),
            Plugins.gapCursor(),
            Plugins.placeholder(this, this.editable ? "Type here" : ""),
          ],
        }),
        editable: () => this.editable,
      }
    );
  }

  static $styles = [
    css`
      :host {
        width: 200px;
        min-height: 24px;
        overflow-x: hidden;
        cursor: text;
      }

      :host([disabled]) {
        cursor: default;
      }

      #editor {
        background-clip: padding-box;
        height: 100%;
        padding: var(--editor-padding, 8px);
        overflow-y: scroll;
        color: var(--secondary-text-color);
      }

      #editor > div {
        flex-grow: 1;
      }
    `,
    css`
      p {
        --margin-top: 0px;
        --margin-bottom: 8px;
      }
    `,
  ];

  static $html = template`
    <std-col id=editor></std-col>
    <div id=content></div>
  `;
}

function RcPatched(t, e, r) {
  for (let n in e)
    n != "class" &&
      n != "style" &&
      n != "nodeName" &&
      !(n in r) &&
      t.removeAttribute(n);
  for (let n in r)
    n != "class" &&
      n != "style" &&
      n != "nodeName" &&
      r[n] != e[n] &&
      t.setAttribute(n, r[n]);
  if (e.class != r.class) {
    let n = e.class ? e.class.split(" ").filter(Boolean) : [],
      u = r.class ? r.class.split(" ").filter(Boolean) : [];
    for (let i = 0; i < n.length; i++)
      u.indexOf(n[i]) == -1 && t.classList.remove(n[i]);
    for (let i = 0; i < u.length; i++)
      n.indexOf(u[i]) == -1 && t.classList.add(u[i]);
    t.classList.length == 0 && t.removeAttribute("class");
  }
  if (e.style != r.style) {
    if (e.style) {
      let n =
          /\s*([\w\-\xa1-\uffff]+)\s*:(?:"(?:\\.|[^"])*"|'(?:\\.|[^'])*'|\(.*?\)|[^;])*/g,
        u;
      for (; (u = n.exec(e.style)); ) t.style.removeProperty(u[1]);
    }
    r.style && (t.style.cssText += r.style);
  }
}

window["Rc"] = (t, e, r) => $$.mutateEnd(() => RcPatched(t, e, r));

// prosemirror.EditorView.prototype.destroy = (hello) => {
//   dev("DESTROYING", this.docView, this, "???");
//   this.docView &&
//     (u0(this),
//     this.destroyPluginViews(),
//     this.mounted
//       ? (this.docView.update(this.state.doc, [], Bn(this), this),
//         (this.dom.textContent = ""))
//       : this.dom.parentNode &&
//         this.dom.parentNode.removeChild(this.dom),
//     this.docView.destroy(),
//     (this.docView = null),
//     cc());
// };
// window["Zr"].prototype.destroy = () => {
//   this.docView &&
//     (u0(this),
//     this.destroyPluginViews(),
//     this.mounted
//       ? (this.docView.update(this.state.doc, [], Bn(this), this),
//         (this.dom.textContent = ""))
//       : this.dom.parentNode && this.dom.parentNode.removeChild(this.dom),
//     this.docView.destroy(),
//     (this.docView = null),
//     cc());
// };
